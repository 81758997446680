import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import * as api from "api";
import { AssetSelect } from "module/common";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import WarningIcon from "@mui/icons-material/Warning";

type TAssetPanel = { isSelected?: boolean };

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  padding: "32px",
  flexDirection: "column",
}));

const LoadingContainer = styled("div")(() => ({
  width: "100%",
  height: "calc(100vh - 200px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingBottom: "48px",
}));

const AssetPanel = styled("div")<TAssetPanel>(({ theme, isSelected }) => ({
  width: "100%",
  border: "2px solid #D3D3D3",
  display: "flex",
  padding: "32px",
  marginTop: "16px",
  flexDirection: "column",
  borderRadius: theme.spacing(2),
  ...(isSelected && { border: "2px solid #4166e1" }),
}));

export const Recommendations = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [selection, setSelection] = useState<{ id: number | null; type: "recommendation" | "manual asset" }>();
  const [manualAsset, setManualAsset] = useState<number | null | undefined>();

  const recommendationsQuery = useQuery({
    queryKey: ["missionId", params.missionId],
    queryFn: () => api.generateRecommendations(params.missionId ?? "", { missionId: params.missionId ?? "" }),
    refetchOnWindowFocus: false,
  });

  // FOR DEMO PURPOSES ONLY - for adrian to be able to see custom columns
  // ----------------------------------------------
  const currentuserQuery = useQuery({
    queryKey: ["user"],
    queryFn: () => api.fetchCurrentUser(),
    refetchOnWindowFocus: false,
  });

  const isAdrian = "adrian.wilson@aeromedicalaustralia.com.au" === currentuserQuery.data?.data?.email;
  // ----------------------------------------------

  const recommendations = recommendationsQuery.data?.data.sort(
    (a, b) => (a.position ?? Infinity) - (b.position ?? Infinity)
  );

  const selectRecommendation = async (recommendationId?: number) => {
    setSelection({ id: recommendationId ? recommendationId : null, type: "recommendation" });
  };

  const selectManualAsset = (assetId?: number) => {
    assetId && setManualAsset(assetId);
    setSelection({ id: assetId ?? null, type: "manual asset" });
  };

  const handleSelection = async () => {
    try {
      const mId = params.missionId ?? "";
      if (selection?.type === "recommendation" && selection?.id) {
        await api.selectRecommendation(mId, selection.id, { mId, recommendationId: selection.id });
      }
      if (selection?.type === "manual asset" && selection?.id) {
        await api.updateMission(mId, { mission: { asset_id: selection.id } });
      }
      navigate(`/main/dashboard/mission/edit/${params.missionId}/details`);
    } catch (error) {
      if (error instanceof Error) {
        console.log("error", error);
      }
      return { formError: "Unexpected error!" };
    }
  };

  const TimeConverter = ({ minutes }: { minutes: number }) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return (
      <div>
        {hours}hr {remainingMinutes}min
      </div>
    );
  };

  const formatSpecialCondition = (inputString: string) => {
    const words: string[] = inputString.split("_");
    const formattedWords: string[] = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const formattedString: string = formattedWords.join(" ");
    return formattedString;
  };

  // I can't seem to destructure these from the query
  const fromHospital = recommendations?.[0]?.mission?.tasks?.[0]?.from_hospital?.name;
  const toHospital = recommendations?.[0]?.mission?.tasks?.[0]?.to_hospital?.name;
  const specialCondition = recommendations?.[0]?.mission?.assessment_condition;

  const formatNumberWithCommas = (number?: number) => {
    if (number === undefined) {
      return null;
    }
    return number.toLocaleString();
  };

  return (
    <>
      {recommendationsQuery.isLoading ? (
        <LoadingContainer>
          <CircularProgress color="secondary" />
          <Typography mt={3}>Loading recommendations</Typography>
        </LoadingContainer>
      ) : (
        <Container>
          <Typography variant="h4">Recommendations</Typography>
          <Typography variant="body1" pt={2} mb={2}>
            Recommendations based on the following factors:
          </Typography>
          {specialCondition && (
            <Typography variant="body1" pt={2} mb={2}>
              <b>Special Conditions:</b>&nbsp;
              {formatSpecialCondition(specialCondition || ("" as string))}
            </Typography>
          )}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="body1" mb={2}>
              {fromHospital && <b>Referring Hospital: </b>}
              {fromHospital}&nbsp;&nbsp;
            </Typography>
            <Typography variant="body1" mb={2}>
              {toHospital && <b>Destination Hospital: </b>}
              {toHospital}
            </Typography>
          </Box>
          {recommendations?.length === 0 ? (
            <AssetPanel>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Asset</TableCell>
                    <TableCell>Time to patient</TableCell>
                    <TableCell>Time out of hospital</TableCell>
                    <TableCell>Total mission time</TableCell>
                    {isAdrian && (
                      <TableCell>
                        <i style={{ color: "#616161" }}>Cost</i>
                      </TableCell>
                    )}
                    {isAdrian && (
                      <TableCell>
                        <i style={{ color: "#616161" }}>CO₂</i>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="n/a" sx={{ verticalAlign: "top", "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      N/A
                    </TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>N/A</TableCell>
                    <TableCell>N/A</TableCell>
                    {isAdrian && (
                      <TableCell>
                        <i style={{ color: "#616161" }}>N/A</i>
                      </TableCell>
                    )}
                    {isAdrian && (
                      <TableCell>
                        <i style={{ color: "#616161" }}>N/A</i>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </AssetPanel>
          ) : (
            recommendations?.map((asset, i) => {
              return (
                //  @ts-ignore - this also incorrectly typed the asset id needs to be made mandatory in the swagger type
                <AssetPanel
                  isSelected={asset.id === selection?.id && selection?.type === "recommendation"}
                  key={`Recommendation ${i}`}
                  onClick={() => selectRecommendation(asset.id)}
                >
                  <Typography variant="h3" mb={2}>
                    Recommendation #{i + 1}
                  </Typography>
                  {asset.warning_messages &&
                    asset.warning_messages.map((warning, i) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingLeft: "14px",
                          alignItems: "center",
                          height: "48px",
                          width: "100%",
                          backgroundColor: "#FECACA",
                        }}
                        key={i}
                      >
                        <WarningIcon sx={{ marginRight: "10px", color: "#DC2626" }} /> {warning.message}
                      </Box>
                    ))}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Asset</TableCell>
                        <TableCell>Time to patient</TableCell>
                        <TableCell>Time out of hospital</TableCell>
                        <TableCell>Total mission time</TableCell>
                        {isAdrian && (
                          <TableCell>
                            <i style={{ color: "#616161" }}>Cost</i>
                          </TableCell>
                        )}
                        {isAdrian && (
                          <TableCell>
                            <i style={{ color: "#616161" }}>CO₂</i>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={asset.id}
                        sx={{ verticalAlign: "top", "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography>{asset?.asset?.name}</Typography>
                            <Typography>{asset?.asset?.aircraft?.call_sign}</Typography>
                            <Typography>{asset?.asset?.aircraft?.registration}</Typography>
                            <Typography>{asset?.asset?.road_ambulance?.call_sign}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <TimeConverter minutes={asset.time_to_patient ?? 0} />
                        </TableCell>
                        <TableCell>
                          <TimeConverter minutes={asset.time_out_of_hospital ?? 0} />
                        </TableCell>
                        <TableCell>
                          <TimeConverter minutes={asset.total_mission_time ?? 0} />
                        </TableCell>
                        {isAdrian && (
                          <TableCell>
                            <i style={{ color: "#616161" }}>${formatNumberWithCommas(asset.cost)}</i>
                          </TableCell>
                        )}
                        {isAdrian && (
                          <TableCell>
                            <i style={{ color: "#616161" }}>{Math.ceil(asset.co2_emissions ?? 0)}kg</i>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </AssetPanel>
              );
            })
          )}
          <AssetPanel isSelected={selection?.type === "manual asset"}>
            <Typography variant="h4" mb={2}>
              Manual tasking
            </Typography>
            <AssetSelect
              label="Asset selection"
              value={manualAsset}
              onChange={(e) => {
                const val = e.target.value as number;
                selectManualAsset(val);
              }}
            />
          </AssetPanel>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <LoadingButton
              sx={{ marginTop: "16px", width: "100px" }}
              type="button"
              loading={recommendationsQuery.isLoading}
              variant="contained"
              disabled={selection?.id === undefined}
              onClick={() => {
                handleSelection();
              }}
            >
              Save draft
            </LoadingButton>
          </Box>
        </Container>
      )}
    </>
  );
};
