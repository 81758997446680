import {
  AssetWithRelatedAssetConfigurationEnum,
  CrewAssemblyAvailabilityTypeEnum,
  CrewWithShiftsCrewRoleEnum,
  LegLegTypeEnum,
  MissionAssessmentConditionEnum,
  MissionMedicalTeamEnum,
  MissionWithRelatedAviationRiskEnum,
  MissionWithRelatedCompletionReasonEnum,
  MissionWithRelatedPatientPriorityEnum,
  MissionWithRelatedPlannedDepartureEnum,
} from "type/model/api";
import { NetsMedicalOptions, UserRoles } from "type/model/view";
import { transformEnumValue } from "utility/transformer";
import { transformSelectItems } from "utility/ui";

export const DEFAULT_TIME_ZONE = "Etc/UTC";
export const UNKNOWN = "?";

export const ORDERED_CREW_ROLES: CrewWithShiftsCrewRoleEnum[] = [
  CrewWithShiftsCrewRoleEnum.Pilot,
  CrewWithShiftsCrewRoleEnum.Aco,
  CrewWithShiftsCrewRoleEnum.Doctor,
  CrewWithShiftsCrewRoleEnum.Paramedic,
  CrewWithShiftsCrewRoleEnum.FlightNurse,
  CrewWithShiftsCrewRoleEnum.NetsDoctor,
  CrewWithShiftsCrewRoleEnum.NetsNurse,
];

export const LEG_TYPES: LegLegTypeEnum[] = [LegLegTypeEnum.Air, LegLegTypeEnum.Road];

export const ALL_USER_ROLES = Object.values(UserRoles);

export const SELECTED_ASSET_KINDS_KEY = "selected-asset-kinds";
export const SHOW_COMPLETED_MISSIONS_KEY = "show-completed-missions";

export const VALID_SEARCH_PARAM_KEYS = [SELECTED_ASSET_KINDS_KEY, SHOW_COMPLETED_MISSIONS_KEY];

export const RISK_SCORE_SELECT_ITEMS = transformSelectItems(
  Object.values(MissionWithRelatedAviationRiskEnum).map((value) => ({
    label: transformEnumValue(value)!,
    value,
  }))
);

export const MEDICAL_TEAM_OPTIONS = Object.values(MissionMedicalTeamEnum).map((value) => ({
  label: transformEnumValue(value)!,
  value,
}));

export const PRIORITY_SELECT_ITEMS = transformSelectItems(
  Object.values(MissionWithRelatedPatientPriorityEnum).map((value) => ({
    label: transformEnumValue(value, "upperEach")!,
    value,
  }))
);

export const SPECIAL_CONDITION_SELECT_ITEMS = transformSelectItems(
  Object.values(MissionAssessmentConditionEnum).map((value) => ({
    label: transformEnumValue(value)!,
    value,
  }))
);

export enum WinchEnum {
  Yes = "Yes",
  No = "No",
}

export const WINCH_OPTIONS = Object.values(WinchEnum).map((value) => ({
  label: transformEnumValue(value)!,
  value,
}));

export const PLANNED_DEPARTURE_OPTIONS = Object.values(MissionWithRelatedPlannedDepartureEnum).map((value) => ({
  label: transformEnumValue(value)!,
  value,
}));

export const ASSET_CONFIGURATION_SELECT_ITEMS = transformSelectItems(
  Object.values(AssetWithRelatedAssetConfigurationEnum).map((value) => ({
    label: transformEnumValue(value, "upperEach")!,
    value,
  }))
);

export const STATUS_SELECT_ITEMS = transformSelectItems(
  Object.values(CrewAssemblyAvailabilityTypeEnum).map((value) => ({
    label: transformEnumValue(value, "upperEach")!,
    value,
  }))
);

export const NETS_MEDICAL_TEAM_OPTIONS = Object.values(NetsMedicalOptions).map((value) => ({
  label: transformEnumValue(value)!,
  value,
}));

export const MISSION_COMPLETION_REASON_OPTIONS = [
  {
    label: "Complete - Home base",
    value: MissionWithRelatedCompletionReasonEnum.HomeBase,
  },
  {
    label: "Called off",
    value: MissionWithRelatedCompletionReasonEnum.CalledOff,
  },
  {
    label: "Retasked",
    value: MissionWithRelatedCompletionReasonEnum.Retasked,
  },
  {
    label: "Aircraft unserviceable",
    value: MissionWithRelatedCompletionReasonEnum.AircraftUnserviceable,
  },
  {
    label: "Weather",
    value: MissionWithRelatedCompletionReasonEnum.Weather,
  },
];
