import { Autocomplete, FormControl, MenuItem, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FormSelect } from "component/FormSelect";
import { STATUS_SELECT_ITEMS } from "constant";
import * as api from "api";
import { useEffect, useMemo, useState } from "react";
import { Airport, CrewAssembly, CrewAssemblyAvailabilityTypeEnum, Helipad } from "type/model/api";
import { FormTextInput } from "component/FormTextInput";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { transformEnumValue } from "utility/transformer";
import { COLORS } from "style";
import { CustomInputLabel } from "component/CustomInputLabel";
import { CustomSelect } from "component/CustomSelect";
import { ChevronDownIcon } from "component/Icons";
import { CustomTextField } from "component/CustomTextField";

interface StatusProps {
  crewMemberRole: string | null;
  shiftId?: number;
}

interface CoreProps {
  shiftId: number;
}

export const AvailabilityCrewAssembly = ({ shiftId }: CoreProps) => {
  type PickupOptions = "Airport" | "Helipad";
  const pickupOptions: PickupOptions[] = ["Airport", "Helipad"];
  const [pickupOpt, setPickupOpt] = useState<PickupOptions>("Airport");
  const [airports, setAirports] = useState<Airport[]>();
  const [helipads, setHelipads] = useState<Helipad[]>();
  const [selectedAirport, setSelectedAirport] = useState<number | null>(null);
  const [showPickup, setShowPickup] = useState(false);
  const [selectedHelipad, setSelectedHelipad] = useState<number>();
  const params = useParams();

  const { data: assetsQuery, refetch } = useQuery({
    queryKey: ["asset", params.assetId],
    queryFn: () => api.fetchAsset(params.assetId ?? ""),
  });

  const crewAssemblies = useMemo(() => {
    return assetsQuery?.data?.[0].crew_assemblies ?? null;
  }, [assetsQuery?.data]);

  const shifts = useMemo(() => {
    return assetsQuery?.data?.[0].shifts ?? null;
  }, [assetsQuery?.data]);

  useEffect(() => {
    const checkAvailabilityType = () => {
      if (crewAssemblies) {
        for (let assembly of crewAssemblies) {
          if (assembly.availability_type === "requires_pickup") {
            setShowPickup(true);
            return;
          }
        }
      }
      setShowPickup(false);
    };
    checkAvailabilityType();
    const foundShift = shifts?.find((shift) => shift.id === shiftId);
    if (foundShift?.pickup_airport_id) {
      setPickupOpt("Airport");
      setSelectedAirport(foundShift?.pickup_airport_id);
    } else if (foundShift?.pickup_helipad_id) {
      setPickupOpt("Helipad");
      setSelectedHelipad(foundShift?.pickup_helipad_id);
    }
  }, [crewAssemblies, shifts, shiftId]);

  useEffect(() => {
    const fetchPickupOpts = async () => {
      const airports = await api.fetchAirports();
      setAirports(airports.data);
      const helipads = await api.fetchHelipads();
      setHelipads(helipads.data);
    };
    fetchPickupOpts();
  }, []);

  const handleOnChangePickupOpt = (event: SelectChangeEvent<unknown>) => {
    setPickupOpt(event.target.value as PickupOptions);
  };

  const roles = [
    { title: "Flight Crew", value: "flight_crew" },
    { title: "Nurse/ Paramedic", value: "nurse_paramedic" },
    { title: "Doctor", value: "doctor" },
    { title: "NETS Team", value: "nets_team" },
  ];

  const StatusSelect = ({ crewMemberRole, shiftId }: StatusProps) => {
    const [delay, setDelay] = useState(0);

    const findCrewAssembly = () =>
      crewAssemblies?.find((assembly) => assembly.shift_id === shiftId && crewMemberRole === assembly.crew_role);

    useEffect(() => {
      const availabilityDelay = findCrewAssembly()?.availability_delay;
      if (availabilityDelay) {
        setDelay(availabilityDelay);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crewAssemblies]);

    const updateCrewAssembly = async (changes: Partial<CrewAssembly>) => {
      const isValidInput = /^\d*$/.test(delay.toString()) || delay.toString() === "" || Number.isNaN(delay.toString());
      if (isValidInput) {
        const crewAssembly = findCrewAssembly();
        if (crewAssembly && crewAssembly.id) {
          await api.updateShiftAssemblyCrew(shiftId!, crewAssembly.id, {
            crew_assembly: { ...changes, crew_role: crewMemberRole! },
          });
          refetch();
        }
      }
    };

    const handleChange = async (event: SelectChangeEvent<unknown>) => {
      const crewAssembly = findCrewAssembly();
      if (crewAssembly) {
        await updateCrewAssembly({
          availability_type: event.target.value as CrewAssemblyAvailabilityTypeEnum,
          availability_delay: 0,
        });
        refetch();
      } else if (crewMemberRole && shiftId) {
        await api.createShiftAssemblyCrew(shiftId, {
          crew_assembly: {
            crew_role: crewMemberRole,
            availability_type: event.target.value as string,
            availability_delay: 0,
          },
        });
        refetch();
      }
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;

      // Check if inputValue is a valid number or an empty string
      const isValidInput = /^\d*$/.test(inputValue) || inputValue === "" || Number.isNaN(inputValue);

      if (isValidInput) {
        setDelay(parseInt(inputValue));
        // Other state updates if needed
      } else {
        // Set delay to 0 when inputValue is not a valid number
        setDelay(0);
      }
    };

    const handleOnBlur = () => {
      updateCrewAssembly({ availability_delay: delay });
    };

    const availabilityType = findCrewAssembly()?.availability_type;

    return (
      <>
        <FormSelect
          sx={{ minWidth: 200, marginBottom: 1 }}
          onChange={handleChange}
          placeholder="Select an availability"
          displayEmpty={true}
          value={availabilityType}
          items={STATUS_SELECT_ITEMS}
        />
        {availabilityType === CrewAssemblyAvailabilityTypeEnum.AvailableWithDelay && (
          <FormTextInput
            label="Minutes"
            placeholder="Enter minutes"
            fullWidth
            value={delay}
            onChange={handleChangeInput}
            onBlur={handleOnBlur}
            type={"number"}
          />
        )}
      </>
    );
  };

  type UpdateData = {
    shift: {
      pickup_airport_id?: number;
      pickup_helipad_id?: number;
    };
  };

  const handlePickupChange = async (event: any, value: Partial<Airport | Helipad>) => {
    if (shiftId && value) {
      const updateData: UpdateData = {
        shift: {},
      };

      if (pickupOpt === "Airport") {
        updateData.shift.pickup_airport_id = value.id;
      } else if (pickupOpt === "Helipad") {
        updateData.shift.pickup_helipad_id = value.id;
      }

      await api.updateShift(shiftId, updateData);
      refetch();
    }
  };

  const getLabel = (airport: Airport | Helipad) => `${airport.name}`;

  return (
    <>
      {roles.map((role) => (
        <Stack
          direction="column"
          sx={{ p: 2, border: `1px solid ${COLORS.DISCO_BALL}`, borderRadius: "4px", gap: 2 }}
          key={role.value}
        >
          <Typography variant="body2">{transformEnumValue(role.title)}</Typography>
          <Stack sx={{ flexGrow: 1, justifyContent: "center" }}>
            <StatusSelect shiftId={shiftId} crewMemberRole={role.value} />
          </Stack>
        </Stack>
      ))}
      {showPickup && (
        <Stack direction="column" sx={{ p: 2, border: `1px solid ${COLORS.DISCO_BALL}`, borderRadius: "4px", gap: 2 }}>
          <FormControl sx={{ marginTop: 1 }}>
            <CustomInputLabel sx={{ marginBottom: "8px" }} required>
              Pickup location
            </CustomInputLabel>
            <CustomSelect
              placeholder="Select a pickup location"
              displayEmpty={true}
              value={pickupOpt}
              onChange={handleOnChangePickupOpt}
              renderValue={(value) => {
                const opt = pickupOptions.find((opt) => opt === value);
                return opt ? opt : "Select a pickup location";
              }}
              size="small"
              sx={{ minWidth: 350 }}
              variant="outlined"
              IconComponent={ChevronDownIcon}
              MenuProps={{
                disableAutoFocusItem: true,
                MenuListProps: { sx: { bgcolor: COLORS.DR_WHITE } },
              }}
            >
              {pickupOptions.map((opt) => (
                <MenuItem key={opt} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          {pickupOpt === "Airport" && airports && (
            <Autocomplete
              disableClearable
              popupIcon={<ChevronDownIcon />}
              isOptionEqualToValue={(option, value) => option?.id?.toString() === value?.id?.toString()}
              value={airports.find((air) => air.id === selectedAirport)}
              onChange={handlePickupChange}
              size="small"
              options={airports}
              getOptionLabel={getLabel}
              renderInput={(props) => <CustomTextField placeholder="Enter an airport name" {...props} />}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  <Typography variant="body2">{getLabel(option)}</Typography>
                </MenuItem>
              )}
            />
          )}
          {pickupOpt === "Helipad" && helipads && (
            <Autocomplete
              disableClearable
              popupIcon={<ChevronDownIcon />}
              isOptionEqualToValue={(option, value) => option?.id?.toString() === value?.id?.toString()}
              value={helipads.find((heli) => heli.id === selectedHelipad)}
              onChange={handlePickupChange}
              size="small"
              options={helipads}
              getOptionLabel={getLabel}
              renderInput={(props) => <CustomTextField placeholder="Enter a helipad name" {...props} />}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  <Typography variant="body2">{getLabel(option)}</Typography>
                </MenuItem>
              )}
            />
          )}
        </Stack>
      )}
    </>
  );
};
