import * as api from "api";
import { Asset, Helipad } from "type/model/api";
import { AssetDetailAutocomplete } from "./AssetDetailAutocomplete";
import { Identifiable } from "type/model/view";
import { QueryFunction } from "@tanstack/react-query";

interface Props {
  value: number | null;
}

export const HelipadSelect = ({ value }: Props) => {
  const queryKey = ["helipads"];
  const queryFn = api.fetchHelipads as QueryFunction<{ data: Identifiable[] }>;

  const getLabel = (helipad: Helipad) => {
    return `${helipad.name} - ${helipad.code}`;
  };

  // Swagger Asset type needs to be updated for null type, using unknown as a workaround
  const getUpdatePayload = (selection: Identifiable) => {
    return {
      home_base_helipad_id: selection.id,
      home_base_airport_id: null,
    } as unknown as Partial<Asset>;
  };

  return (
    <AssetDetailAutocomplete
      placeholder="Select a helipad"
      queryKey={queryKey}
      queryFn={queryFn}
      getLabel={getLabel}
      clearFields={{ home_base_address_id: null, home_base_airport_id: null }}
      getUpdatePayload={getUpdatePayload}
      value={value || null}
    />
  );
};
