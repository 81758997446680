import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { CustomTab } from "component/CustomTab";
import { CustomTabs } from "component/CustomTabs";
import { DeleteIcon } from "component/Icons";
import { useMemo } from "react";
import {
  ActionFunction,
  Link,
  Outlet,
  Route,
  Routes,
  matchPath,
  redirect,
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { COLORS } from "style";
import { MissionWithRelatedCompletionReasonEnum, MissionWithRelatedStatusEnum, User } from "type/model/api";
import * as api from "api";
import { transformMissionView } from "utility/transformer";
import { LoadingIndicator } from "component/LoadingIndicator";
import { ErrorAlert } from "component/ErrorAlert";
import { DeleteConfirmationDialog } from "./DeleteMissionConfirmationDialog";
import { CompleteMissionConfirmationDialog } from "./CompleteMissionConfirmationDialog";
import { MarkMissionAs } from "./MarkMissionAs";
import { pathWithSearchParams } from "utility/router";

const tabs = [
  {
    label: "Details",
    to: "details",
    matchPattern: "/main/dashboard/mission/edit/:missionId/details/*",
    disabled: false,
  },
  {
    label: "Legs",
    to: "legs",
    matchPattern: "/main/dashboard/mission/edit/:missionId/legs/*",
    disabled: false,
  },
];

const deleteMission = async (missionId: string) => {
  try {
    await api.deleteMission(missionId);
    api.queryClient.invalidateQueries({ queryKey: ["missions"] });
    return redirect("/main/dashboard");
  } catch (error) {
    if (error instanceof Error) {
      return { formError: error.message };
    }
    return { formError: "Unexpected error!" };
  }
};

const updateMission = async (missionId: string, formData: FormData) => {
  const missionJSON = formData.get("mission");

  if (missionJSON && typeof missionJSON === "string") {
    try {
      await api.updateMission(missionId, { mission: JSON.parse(missionJSON) });
      api.queryClient.invalidateQueries({ queryKey: ["mission", missionId] });
      return redirect(pathWithSearchParams("."));
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const editMissionLayoutAction: ActionFunction = async ({ request, params }) => {
  const formData = await request.formData();
  const action = formData.get("action");

  switch (action) {
    case "delete":
      return await deleteMission(params.missionId!);
    case "update":
      return await updateMission(params.missionId!, formData);
    default:
      return null;
  }
};

export const EditMissionLayout = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const readOnly = !currentUser.is_tasker;
  const missionQuery = useQuery({
    queryKey: ["mission", params.missionId],
    queryFn: () => api.fetchMission(params.missionId ?? ""),
    enabled: !!params.missionId,
    refetchOnWindowFocus: false,
  });

  const mission = useMemo(
    () => missionQuery?.data?.data?.map(transformMissionView)?.[0] ?? null,

    [missionQuery?.data?.data]
  );

  const canComplete = useMemo(() => {
    const allLegsHaveTimes = mission?.legs.every(
      (leg) => (!!leg.enteredStartTime && !!leg.enteredEndTime) || (!!leg.calcStartTime && !!leg.calcEndTime)
    );

    return !!mission?.mru && allLegsHaveTimes;
  }, [mission]);

  const selectedTabIndex = useMemo(
    () =>
      Math.max(
        tabs.findIndex((tab) => matchPath(tab.matchPattern, pathname)),
        0
      ),
    [pathname]
  );

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    navigate(pathWithSearchParams(tabs[newValue].to));
  };

  const handleDialogClose = () => {
    navigate(pathWithSearchParams(tabs[selectedTabIndex].to));
  };

  if (missionQuery.isLoading) {
    return <LoadingIndicator />;
  }

  if (missionQuery.isError || !mission) {
    return <ErrorAlert>Something went wrong! Please try again.</ErrorAlert>;
  }

  const handleMarkAsClick = (value: MissionWithRelatedCompletionReasonEnum) => {
    navigate(pathWithSearchParams(`${tabs[selectedTabIndex].to}/complete-mission/${value}`));
  };

  return (
    <>
      <Stack sx={{ width: "100%", borderBottom: `1px solid ${COLORS.CHRISTMAS_SILVER}`, gap: 4 }}>
        <Stack direction="row" sx={{ px: 2, justifyContent: "space-between", minHeight: 43 }}>
          <Typography variant="h1" color={COLORS.CYNICAL_BLACK}>
            Mission
          </Typography>
          <Routes>
            <Route
              path="details/*"
              element={
                !readOnly ? (
                  <Stack direction="row" sx={{ gap: 1 }}>
                    {!canComplete ? (
                      <Tooltip
                        title={
                          mission?.status !== MissionWithRelatedStatusEnum.Draft
                            ? "MRU is required. Legs may not have incomplete times."
                            : null
                        }
                        placement="bottom-end"
                      >
                        <span>
                          <MarkMissionAs disabled={true} onSelect={handleMarkAsClick} />
                        </span>
                      </Tooltip>
                    ) : mission?.status === MissionWithRelatedStatusEnum.Confirmed ? (
                      <MarkMissionAs disabled={false} onSelect={handleMarkAsClick} />
                    ) : null}
                    {mission?.status === MissionWithRelatedStatusEnum.Draft ? (
                      <Button
                        component={Link}
                        to={pathWithSearchParams("delete-mission")}
                        variant="contained"
                        color="error"
                        sx={{ minWidth: 44, p: 0 }}
                      >
                        <DeleteIcon />
                      </Button>
                    ) : null}
                  </Stack>
                ) : null
              }
            />
          </Routes>
        </Stack>
        <CustomTabs value={selectedTabIndex} onChange={handleChange} sx={{ mb: "-1px" }}>
          {tabs.map((tab, index) => (
            <CustomTab key={index} label={tab.label} disabled={tab.disabled} />
          ))}
        </CustomTabs>
      </Stack>
      <Stack sx={{ py: 2, width: "100%" }}>
        <Outlet />
      </Stack>
      <Routes>
        <Route path="details/delete-mission" element={<DeleteConfirmationDialog onClose={handleDialogClose} />} />
        <Route path="legs/delete-mission" element={<DeleteConfirmationDialog onClose={handleDialogClose} />} />

        <Route
          path="details/complete-mission/:reason"
          element={<CompleteMissionConfirmationDialog onClose={handleDialogClose} />}
        />
        <Route
          path="legs/complete-mission/:reason"
          element={<CompleteMissionConfirmationDialog onClose={handleDialogClose} />}
        />
      </Routes>
    </>
  );
};
