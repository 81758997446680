/**
 * Address model
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {number}
   * @memberof Address
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line1?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line2?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  state?: string;
  /**
   *
   * @type {number}
   * @memberof Address
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof Address
   */
  _long?: number;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  updated_at?: string;
}
/**
 * Multiple Address response
 * @export
 * @interface AddressesResponse
 */
export interface AddressesResponse {
  /**
   *
   * @type {Array<Address>}
   * @memberof AddressesResponse
   */
  data: Array<Address>;
  /**
   *
   * @type {MetaResponse}
   * @memberof AddressesResponse
   */
  meta: MetaResponse;
}
/**
 * Aircraft model
 * @export
 * @interface Aircraft
 */
export interface Aircraft {
  /**
   *
   * @type {number}
   * @memberof Aircraft
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Aircraft
   */
  call_sign?: string;
  /**
   *
   * @type {string}
   * @memberof Aircraft
   */
  registration?: string;
  /**
   *
   * @type {string}
   * @memberof Aircraft
   */
  aircraft_kind?: AircraftAircraftKindEnum;
  /**
   *
   * @type {number}
   * @memberof Aircraft
   */
  aircraft_type_id?: number;
  /**
   *
   * @type {string}
   * @memberof Aircraft
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Aircraft
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AircraftAircraftKindEnum {
  Aeroplane = "aeroplane",
  Helicopter = "helicopter",
}

/**
 * Multiple Aircraft response
 * @export
 * @interface AircraftResponse
 */
export interface AircraftResponse {
  /**
   *
   * @type {Array<Aircraft>}
   * @memberof AircraftResponse
   */
  data: Array<Aircraft>;
  /**
   *
   * @type {MetaResponse}
   * @memberof AircraftResponse
   */
  meta: MetaResponse;
}
/**
 * Airport model
 * @export
 * @interface Airport
 */
export interface Airport {
  /**
   *
   * @type {number}
   * @memberof Airport
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Airport
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof Airport
   */
  _long?: number;
  /**
   *
   * @type {number}
   * @memberof Airport
   */
  road_access_address_id?: number;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  fuel_available?: AirportFuelAvailableEnum;
  /**
   *
   * @type {number}
   * @memberof Airport
   */
  nearest_fuel_helipad_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof Airport
   */
  is_available?: boolean;
  /**
   *
   * @type {number}
   * @memberof Airport
   */
  runway_length?: number;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  alternate_minima?: AirportAlternateMinimaEnum;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  landing_minima?: AirportLandingMinimaEnum;
  /**
   *
   * @type {boolean}
   * @memberof Airport
   */
  is_lighting_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Airport
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AirportFuelAvailableEnum {
  Truck = "truck",
  Bowser = "bowser",
  Drum = "drum",
}
/**
 * @export
 * @enum {string}
 */
export enum AirportAlternateMinimaEnum {
  Cloud = "cloud",
  Visibility = "visibility",
}
/**
 * @export
 * @enum {string}
 */
export enum AirportLandingMinimaEnum {
  Cloud = "cloud",
  Visibility = "visibility",
}

/**
 * Multiple Helipad response
 * @export
 * @interface AirportsResponse
 */
export interface AirportsResponse {
  /**
   *
   * @type {Array<Airport>}
   * @memberof AirportsResponse
   */
  data: Array<Airport>;
  /**
   *
   * @type {MetaResponse}
   * @memberof AirportsResponse
   */
  meta: MetaResponse;
}
/**
 *
 * @export
 * @interface ApiV1InvitationUser
 */
export interface ApiV1InvitationUser {
  /**
   *
   * @type {string}
   * @memberof ApiV1InvitationUser
   */
  invitation_token: string;
  /**
   *
   * @type {string}
   * @memberof ApiV1InvitationUser
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof ApiV1InvitationUser
   */
  password_confirmation?: string;
}
/**
 *
 * @export
 * @interface ApiV1LoginUser
 */
export interface ApiV1LoginUser {
  /**
   *
   * @type {string}
   * @memberof ApiV1LoginUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ApiV1LoginUser
   */
  password: string;
}
/**
 *
 * @export
 * @interface ApiV1PasswordUser
 */
export interface ApiV1PasswordUser {
  /**
   *
   * @type {string}
   * @memberof ApiV1PasswordUser
   */
  reset_password_token: string;
  /**
   *
   * @type {string}
   * @memberof ApiV1PasswordUser
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof ApiV1PasswordUser
   */
  password_confirmation?: string;
}
/**
 *
 * @export
 * @interface ApiV1PasswordUser1
 */
export interface ApiV1PasswordUser1 {
  /**
   *
   * @type {string}
   * @memberof ApiV1PasswordUser1
   */
  email: string;
}
/**
 *
 * @export
 * @interface ApiV1UsersCurrentMe
 */
export interface ApiV1UsersCurrentMe {
  /**
   *
   * @type {string}
   * @memberof ApiV1UsersCurrentMe
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiV1UsersCurrentMe
   */
  last_name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiV1UsersCurrentMe
   */
  is_observer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiV1UsersCurrentMe
   */
  is_tasker?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiV1UsersCurrentMe
   */
  is_manager?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiV1UsersCurrentMe
   */
  position?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiV1UsersCurrentMe
   */
  is_rostered?: boolean;
  /**
   *
   * @type {string}
   * @memberof ApiV1UsersCurrentMe
   */
  timezone?: string;
}
/**
 *
 * @export
 * @interface ApiV1UsersCurrentMe1
 */
export interface ApiV1UsersCurrentMe1 {
  /**
   *
   * @type {string}
   * @memberof ApiV1UsersCurrentMe1
   */
  last_name?: string;
}
/**
 * Asset model
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  asset_kind?: AssetAssetKindEnum;
  /**
   * FK reference to Airport for aeroplane
   * @type {number}
   * @memberof Asset
   */
  home_base_airport_id?: number;
  /**
   * FK reference to Address for helicopter
   * @type {number}
   * @memberof Asset
   */
  home_base_helipad_id?: number;
  /**
   * FK reference to Address for road_ambulance
   * @type {number}
   * @memberof Asset
   */
  home_base_address_id?: number;
  /**
   * FK reference to Aircraft
   * @type {number}
   * @memberof Asset
   */
  aircraft_id?: number;
  /**
   * FK reference to RoadAmbulance
   * @type {number}
   * @memberof Asset
   */
  road_ambulance_id?: number;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  asset_configuration?: AssetAssetConfigurationEnum;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  dashboard_order?: number;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AssetAssetKindEnum {
  Aeroplane = "aeroplane",
  Helicopter = "helicopter",
  RoadAmbulance = "road_ambulance",
}
/**
 * @export
 * @enum {string}
 */
export enum AssetAssetConfigurationEnum {
  Rscu = "rscu",
  NetsN = "nets_n",
  NetsP = "nets_p",
  Sps = "sps",
  Other = "other",
  RscuNoWinch = "rscu_no_winch",
  FixedWing = "fixed_wing",
  Road = "road",
}

/**
 * Asset abbreviated data
 * @export
 * @interface AssetAbbreviated
 */
export interface AssetAbbreviated {
  /**
   *
   * @type {number}
   * @memberof AssetAbbreviated
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AssetAbbreviated
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssetAbbreviated
   */
  asset_kind?: AssetAbbreviatedAssetKindEnum;
  /**
   * FK reference to Address for road_ambulance
   * @type {number}
   * @memberof AssetAbbreviated
   */
  home_base_address_id?: number;
  /**
   * FK reference to Airport for aeroplane
   * @type {number}
   * @memberof AssetAbbreviated
   */
  home_base_airport_id?: number;
  /**
   * FK reference to Address for helicopter
   * @type {number}
   * @memberof AssetAbbreviated
   */
  home_base_helipad_id?: number;
  /**
   * FK reference to Aircraft
   * @type {number}
   * @memberof AssetAbbreviated
   */
  aircraft_id?: number;
  /**
   * FK reference to RoadAmbulance
   * @type {number}
   * @memberof AssetAbbreviated
   */
  road_ambulance_id?: number;
  /**
   *
   * @type {string}
   * @memberof AssetAbbreviated
   */
  asset_configuration?: AssetAbbreviatedAssetConfigurationEnum;
  /**
   *
   * @type {string}
   * @memberof AssetAbbreviated
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof AssetAbbreviated
   */
  dashboard_order?: number;
  /**
   *
   * @type {string}
   * @memberof AssetAbbreviated
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof AssetAbbreviated
   */
  updated_at?: string;
  /**
   *
   * @type {Aircraft}
   * @memberof AssetAbbreviated
   */
  aircraft?: Aircraft;
  /**
   *
   * @type {RoadAmbulance}
   * @memberof AssetAbbreviated
   */
  road_ambulance?: RoadAmbulance;
}

/**
 * @export
 * @enum {string}
 */
export enum AssetAbbreviatedAssetKindEnum {
  Aeroplane = "aeroplane",
  Helicopter = "helicopter",
  RoadAmbulance = "road_ambulance",
}
/**
 * @export
 * @enum {string}
 */
export enum AssetAbbreviatedAssetConfigurationEnum {
  Rscu = "rscu",
  NetsN = "nets_n",
  NetsP = "nets_p",
  Sps = "sps",
  Other = "other",
  RscuNoWinch = "rscu_no_winch",
  FixedWing = "fixed_wing",
  Road = "road",
}

/**
 * Asset request
 * @export
 * @interface AssetRequest
 */
export interface AssetRequest {
  /**
   *
   * @type {object}
   * @memberof AssetRequest
   */
  asset: object;
}
/**
 * Single Asset response
 * @export
 * @interface AssetResponse
 */
export interface AssetResponse {
  /**
   *
   * @type {AssetWithRelated}
   * @memberof AssetResponse
   */
  data: AssetWithRelated;
}
/**
 * Asset including related data
 * @export
 * @interface AssetWithRelated
 */
export interface AssetWithRelated {
  /**
   *
   * @type {number}
   * @memberof AssetWithRelated
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AssetWithRelated
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssetWithRelated
   */
  asset_kind?: AssetWithRelatedAssetKindEnum;
  /**
   * FK reference to Airport for aeroplane
   * @type {number}
   * @memberof AssetWithRelated
   */
  home_base_airport_id?: number;
  /**
   * FK reference to Address for helicopter
   * @type {number}
   * @memberof AssetWithRelated
   */
  home_base_helipad_id?: number;
  /**
   * FK reference to Address for road_ambulance
   * @type {number}
   * @memberof AssetWithRelated
   */
  home_base_address_id?: number;
  /**
   * FK reference to Aircraft
   * @type {number}
   * @memberof AssetWithRelated
   */
  aircraft_id?: number;
  /**
   * FK reference to RoadAmbulance
   * @type {number}
   * @memberof AssetWithRelated
   */
  road_ambulance_id?: number;
  /**
   *
   * @type {string}
   * @memberof AssetWithRelated
   */
  asset_configuration?: AssetWithRelatedAssetConfigurationEnum;
  /**
   *
   * @type {string}
   * @memberof AssetWithRelated
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof AssetWithRelated
   */
  dashboard_order?: number;
  /**
   *
   * @type {string}
   * @memberof AssetWithRelated
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof AssetWithRelated
   */
  updated_at?: string;
  /**
   *
   * @type {Aircraft}
   * @memberof AssetWithRelated
   */
  aircraft?: Aircraft;
  /**
   *
   * @type {RoadAmbulance}
   * @memberof AssetWithRelated
   */
  road_ambulance?: RoadAmbulance;
  /**
   *
   * @type {Array<CrewWithShifts>}
   * @memberof AssetWithRelated
   */
  crew?: Array<CrewWithShifts>;
  /**
   *
   * @type {Array<CrewAssembly>}
   * @memberof AssetWithRelated
   */
  crew_assemblies?: Array<CrewAssembly>;
  /**
   *
   * @type {Array<Shift>}
   * @memberof AssetWithRelated
   */
  shifts?: Array<Shift>;
  /**
   * Returns current or future unavailabilities
   * @type {Array<Unavailability>}
   * @memberof AssetWithRelated
   */
  unavailabilities?: Array<Unavailability>;
}

/**
 * @export
 * @enum {string}
 */
export enum AssetWithRelatedAssetKindEnum {
  Aeroplane = "aeroplane",
  Helicopter = "helicopter",
  RoadAmbulance = "road_ambulance",
}
/**
 * @export
 * @enum {string}
 */
export enum AssetWithRelatedAssetConfigurationEnum {
  Rscu = "rscu",
  NetsN = "nets_n",
  NetsP = "nets_p",
  Sps = "sps",
  Other = "other",
  RscuNoWinch = "rscu_no_winch",
  FixedWing = "fixed_wing",
  Road = "road",
}

/**
 * Multiple Assets response
 * @export
 * @interface AssetsResponse
 */
export interface AssetsResponse {
  /**
   *
   * @type {Array<AssetWithRelated>}
   * @memberof AssetsResponse
   */
  data: Array<AssetWithRelated>;
  /**
   *
   * @type {MetaResponse}
   * @memberof AssetsResponse
   */
  meta: MetaResponse;
}
/**
 * Crew model
 * @export
 * @interface Crew
 */
export interface Crew {
  /**
   *
   * @type {number}
   * @memberof Crew
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Crew
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof Crew
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof Crew
   */
  crew_role: CrewCrewRoleEnum;
  /**
   *
   * @type {string}
   * @memberof Crew
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Crew
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CrewCrewRoleEnum {
  Aco = "aco",
  Doctor = "doctor",
  FlightNurse = "flight_nurse",
  NetsDoctor = "nets_doctor",
  NetsNurse = "nets_nurse",
  Paramedic = "paramedic",
  Pilot = "pilot",
}

/**
 * CrewAssembly Model
 * @export
 * @interface CrewAssembly
 */
export interface CrewAssembly {
  /**
   *
   * @type {number}
   * @memberof CrewAssembly
   */
  id?: number;
  /**
   * FK reference to Shift
   * @type {number}
   * @memberof CrewAssembly
   */
  shift_id: number;
  /**
   *
   * @type {string}
   * @memberof CrewAssembly
   */
  crew_role: CrewAssemblyCrewRoleEnum;
  /**
   *
   * @type {string}
   * @memberof CrewAssembly
   */
  availability_type: CrewAssemblyAvailabilityTypeEnum;
  /**
   * Required if availability_type == available_with_delay
   * @type {number}
   * @memberof CrewAssembly
   */
  availability_delay?: number;
  /**
   *
   * @type {string}
   * @memberof CrewAssembly
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CrewAssembly
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum CrewAssemblyCrewRoleEnum {
  FlightCrew = "flight_crew",
  NurseParamedic = "nurse_paramedic",
  Doctor = "doctor",
  NetsTeam = "nets_team",
}
/**
 * @export
 * @enum {string}
 */
export enum CrewAssemblyAvailabilityTypeEnum {
  Ready = "ready",
  AvailableWithDelay = "available_with_delay",
  RequiresPickup = "requires_pickup",
  Unavailable = "unavailable",
}

/**
 * CrewAssembly request
 * @export
 * @interface CrewAssemblyRequest
 */
export interface CrewAssemblyRequest {
  /**
   *
   * @type {object}
   * @memberof CrewAssemblyRequest
   */
  crew_assembly: object;
}
/**
 * Single CrewAssembly response
 * @export
 * @interface CrewAssemblyResponse
 */
export interface CrewAssemblyResponse {
  /**
   *
   * @type {CrewAssembly}
   * @memberof CrewAssemblyResponse
   */
  data: CrewAssembly;
}
/**
 * Crew Index response
 * @export
 * @interface CrewIndexResponse
 */
export interface CrewIndexResponse {
  /**
   *
   * @type {Array<CrewWithShifts>}
   * @memberof CrewIndexResponse
   */
  data: Array<CrewWithShifts>;
  /**
   *
   * @type {MetaResponse}
   * @memberof CrewIndexResponse
   */
  meta: MetaResponse;
}
/**
 * Crew with related Shifts
 * @export
 * @interface CrewWithShifts
 */
export interface CrewWithShifts {
  /**
   *
   * @type {number}
   * @memberof CrewWithShifts
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CrewWithShifts
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CrewWithShifts
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CrewWithShifts
   */
  crew_role: CrewWithShiftsCrewRoleEnum;
  /**
   *
   * @type {string}
   * @memberof CrewWithShifts
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CrewWithShifts
   */
  updated_at?: string;
  /**
   * Array of Shift IDs
   * @type {Array}
   * @memberof CrewWithShifts
   */
  shift_ids?: Array<number>;
  /**
   *
   * @type {Array<Shift>}
   * @memberof CrewWithShifts
   */
  shifts?: Array<Shift>;
}

/**
 * @export
 * @enum {string}
 */
export enum CrewWithShiftsCrewRoleEnum {
  Aco = "aco",
  Doctor = "doctor",
  FlightNurse = "flight_nurse",
  NetsDoctor = "nets_doctor",
  NetsNurse = "nets_nurse",
  Paramedic = "paramedic",
  Pilot = "pilot",
}

/**
 * Error response
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  error: string;
}
/**
 * Errors response
 * @export
 * @interface ErrorsResponse
 */
export interface ErrorsResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ErrorsResponse
   */
  errors: Array<string>;
}
/**
 * Forecast model
 * @export
 * @interface Forecast
 */
export interface Forecast {
  /**
   *
   * @type {number}
   * @memberof Forecast
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Forecast
   */
  start_at?: string;
  /**
   *
   * @type {string}
   * @memberof Forecast
   */
  end_at?: string;
  /**
   *
   * @type {string}
   * @memberof Forecast
   */
  requirements?: ForecastRequirementsEnum;
  /**
   * FK reference to Airport
   * @type {number}
   * @memberof Forecast
   */
  airport_id?: number;
  /**
   *
   * @type {string}
   * @memberof Forecast
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Forecast
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ForecastRequirementsEnum {
  NoRequirement = "no_requirement",
  Intermittant = "intermittant",
  Temporary = "temporary",
  Alternate = "alternate",
}

/**
 * Helipad model
 * @export
 * @interface Helipad
 */
export interface Helipad {
  /**
   *
   * @type {number}
   * @memberof Helipad
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Helipad
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Helipad
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Helipad
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof Helipad
   */
  _long?: number;
  /**
   *
   * @type {number}
   * @memberof Helipad
   */
  access_gate_address_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof Helipad
   */
  is_ambulance_required?: boolean;
  /**
   *
   * @type {string}
   * @memberof Helipad
   */
  fuel_available?: HelipadFuelAvailableEnum;
  /**
   *
   * @type {number}
   * @memberof Helipad
   */
  nearest_fuel_helipad_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof Helipad
   */
  is_available?: boolean;
  /**
   *
   * @type {string}
   * @memberof Helipad
   */
  surface: HelipadSurfaceEnum;
  /**
   *
   * @type {string}
   * @memberof Helipad
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Helipad
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum HelipadFuelAvailableEnum {
  Truck = "truck",
  Bowser = "bowser",
  Drum = "drum",
}
/**
 * @export
 * @enum {string}
 */
export enum HelipadSurfaceEnum {
  Grass = "grass",
  Sealed = "sealed",
}

/**
 * Multiple Helipad response
 * @export
 * @interface HelipadsResponse
 */
export interface HelipadsResponse {
  /**
   *
   * @type {Array<Helipad>}
   * @memberof HelipadsResponse
   */
  data: Array<Helipad>;
  /**
   *
   * @type {MetaResponse}
   * @memberof HelipadsResponse
   */
  meta: MetaResponse;
}
/**
 * Hospital model
 * @export
 * @interface Hospital
 */
export interface Hospital {
  /**
   *
   * @type {number}
   * @memberof Hospital
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Hospital
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Hospital
   */
  alternate_name?: string;
  /**
   * FK reference to Address
   * @type {number}
   * @memberof Hospital
   */
  ambulance_bay_address_id?: number;
  /**
   *
   * @type {boolean}
   * @memberof Hospital
   */
  is_sydney_hospital?: boolean;
  /**
   * FK reference to Helipad
   * @type {number}
   * @memberof Hospital
   */
  helipad_id?: number;
  /**
   * FK reference to Helipad
   * @type {number}
   * @memberof Hospital
   */
  nearest_helipad_id?: number;
  /**
   * FK reference to Airport
   * @type {number}
   * @memberof Hospital
   */
  second_nearest_helipad_id?: number;
  /**
   * FK reference to Airport
   * @type {number}
   * @memberof Hospital
   */
  nearest_airport_id?: number;
  /**
   * FK reference to Airport
   * @type {number}
   * @memberof Hospital
   */
  second_nearest_airport_id?: number;
  /**
   *
   * @type {string}
   * @memberof Hospital
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Hospital
   */
  updated_at?: string;
}
/**
 * Abbreviated Hospital reponse
 * @export
 * @interface HospitalAbbreviated
 */
export interface HospitalAbbreviated {
  /**
   *
   * @type {number}
   * @memberof HospitalAbbreviated
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof HospitalAbbreviated
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof HospitalAbbreviated
   */
  alternate_name?: string;
}
/**
 * Multiple Hospital response
 * @export
 * @interface HospitalsResponse
 */
export interface HospitalsResponse {
  /**
   *
   * @type {Array<Hospital>}
   * @memberof HospitalsResponse
   */
  data: Array<Hospital>;
  /**
   *
   * @type {MetaResponse}
   * @memberof HospitalsResponse
   */
  meta: MetaResponse;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {ApiV1UsersCurrentMe}
   * @memberof InlineObject
   */
  me?: ApiV1UsersCurrentMe;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {ApiV1UsersCurrentMe1}
   * @memberof InlineObject1
   */
  me: ApiV1UsersCurrentMe1;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {ApiV1LoginUser}
   * @memberof InlineObject2
   */
  user: ApiV1LoginUser;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
  /**
   *
   * @type {ApiV1PasswordUser}
   * @memberof InlineObject3
   */
  user: ApiV1PasswordUser;
}
/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
  /**
   *
   * @type {ApiV1PasswordUser1}
   * @memberof InlineObject4
   */
  user: ApiV1PasswordUser1;
}
/**
 *
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
  /**
   *
   * @type {ApiV1InvitationUser}
   * @memberof InlineObject5
   */
  user: ApiV1InvitationUser;
}
/**
 * Leg model
 * @export
 * @interface Leg
 */
export interface Leg {
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  calculated_start_at?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  calculated_end_at?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  entered_start_at?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  entered_end_at?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  sar_at?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  leg_type?: LegLegTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof Leg
   */
  visible?: boolean;
  /**
   * Not implemented
   * @type {number}
   * @memberof Leg
   */
  location_from_id?: number;
  /**
   * Not implemented
   * @type {number}
   * @memberof Leg
   */
  location_to_id?: number;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  from_text?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  to_text?: string;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  minutes?: number;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  pob?: number;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  fuel?: number;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  flight_time?: number;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  ground_time?: number;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  stage?: LegStageEnum;
  /**
   * Not implemented
   * @type {number}
   * @memberof Leg
   */
  task_id?: number;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  mission_id?: number;
  /**
   *
   * @type {number}
   * @memberof Leg
   */
  leg_order?: number;
  /**
   *
   * @type {boolean}
   * @memberof Leg
   */
  is_current?: boolean;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Leg
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LegLegTypeEnum {
  Setup = "setup",
  Air = "air",
  Road = "road",
  Fuel = "fuel",
  Patient = "patient",
}
/**
 * @export
 * @enum {string}
 */
export enum LegStageEnum {
  PreStartTime = "pre_start_time",
  EnrouteToPatient = "enroute_to_patient",
  Refuel = "refuel",
  ToReferringHospital = "to_referring_hospital",
  WithPatient = "with_patient",
  FromReferringHospital = "from_referring_hospital",
  EnrouteWithPatient = "enroute_with_patient",
  ToDestinationHospital = "to_destination_hospital",
  PatientDropOff = "patient_drop_off",
  FromDestinationHospital = "from_destination_hospital",
  EnrouteReturnHome = "enroute_return_home",
}

/**
 * Leg request
 * @export
 * @interface LegRequest
 */
export interface LegRequest {
  /**
   *
   * @type {object}
   * @memberof LegRequest
   */
  leg: object;
}
/**
 * Leg response
 * @export
 * @interface LegResponse
 */
export interface LegResponse {
  /**
   *
   * @type {Leg}
   * @memberof LegResponse
   */
  data: Leg;
}
/**
 * Multiple Legs response
 * @export
 * @interface LegsResponse
 */
export interface LegsResponse {
  /**
   *
   * @type {Array<Leg>}
   * @memberof LegsResponse
   */
  data: Array<Leg>;
  /**
   *
   * @type {MetaResponse}
   * @memberof LegsResponse
   */
  meta: MetaResponse;
}
/**
 * Meta for array responses
 * @export
 * @interface MetaResponse
 */
export interface MetaResponse {
  /**
   *
   * @type {number}
   * @memberof MetaResponse
   */
  total_count: number;
}
/**
 * Mission model
 * @export
 * @interface Mission
 */
export interface Mission {
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  created_by_user_id?: number;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  status?: MissionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  completion_reason?: MissionCompletionReasonEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  tasking_type?: MissionTaskingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  patient_priority?: MissionPatientPriorityEnum;
  /**
   * Risk score
   * @type {string}
   * @memberof Mission
   */
  aviation_risk?: MissionAviationRiskEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  assessment_condition?: MissionAssessmentConditionEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  medical_team?: MissionMedicalTeamEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  planned_departure?: MissionPlannedDepartureEnum;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  planned_departure_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  is_winch_required?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  is_drop_off_only?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  helicopter_access_required?: boolean;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  estimated_return_to_base_at?: string;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  recall_time?: number;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  able_respond_medical_mission?: boolean;
  /**
   * FK reference to Asset
   * @type {number}
   * @memberof Mission
   */
  asset_id?: number;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  mru_number?: string;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  asset_selection_reason?: string;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  comments?: string;
  /**
   *
   * @type {Array<Crew>}
   * @memberof Mission
   */
  crew?: Array<Crew>;
  /**
   *
   * @type {Array<Leg>}
   * @memberof Mission
   */
  legs?: Array<Leg>;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MissionStatusEnum {
  Draft = "draft",
  Confirmed = "confirmed",
  Complete = "complete",
  Cancelled = "cancelled",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionCompletionReasonEnum {
  HomeBase = "home_base",
  CalledOff = "called_off",
  Retasked = "retasked",
  AircraftUnservicable = "aircraft_unservicable",
  Weather = "weather",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionTaskingTypeEnum {
  Primary = "primary",
  Secondary = "secondary",
  SecondaryNets = "secondary_nets",
  Other = "other",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionPatientPriorityEnum {
  P1Immediate = "p1_immediate",
  P1 = "p1",
  P2 = "p2",
  P3 = "p3",
  P4 = "p4",
  P5 = "p5",
  P6 = "p6",
  Na = "na",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAviationRiskEnum {
  NormalOperations = "normal_operations",
  Caution = "caution",
  HighCaution = "high_caution",
  SafetyCritical = "safety_critical",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAssessmentConditionEnum {
  TotalMissionTime = "total_mission_time",
  TimeToPatientCritical = "time_to_patient_critical",
  OutOfHospitalCritical = "out_of_hospital_critical",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionMedicalTeamEnum {
  FlightNurseOnly = "flight_nurse_only",
  DoctorRequired = "doctor_required",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionPlannedDepartureEnum {
  Now = "now",
  SpecificTime = "specific_time",
}

/**
 * Mission with abbreviated data
 * @export
 * @interface MissionAbbreviated
 */
export interface MissionAbbreviated {
  /**
   *
   * @type {number}
   * @memberof MissionAbbreviated
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  status?: MissionAbbreviatedStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  tasking_type?: MissionAbbreviatedTaskingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  patient_priority?: MissionAbbreviatedPatientPriorityEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  assessment_condition?: MissionAbbreviatedAssessmentConditionEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  medical_team?: MissionAbbreviatedMedicalTeamEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  planned_departure?: MissionAbbreviatedPlannedDepartureEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAbbreviated
   */
  planned_departure_at?: string;
  /**
   *
   * @type {Array<TaskWithRelated>}
   * @memberof MissionAbbreviated
   */
  tasks?: Array<TaskWithRelated>;
}

/**
 * @export
 * @enum {string}
 */
export enum MissionAbbreviatedStatusEnum {
  Draft = "draft",
  Confirmed = "confirmed",
  Complete = "complete",
  Cancelled = "cancelled",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAbbreviatedTaskingTypeEnum {
  Primary = "primary",
  Secondary = "secondary",
  SecondaryNets = "secondary_nets",
  Other = "other",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAbbreviatedPatientPriorityEnum {
  P1Immediate = "p1_immediate",
  P1 = "p1",
  P2 = "p2",
  P3 = "p3",
  P4 = "p4",
  P5 = "p5",
  P6 = "p6",
  Na = "na",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAbbreviatedAssessmentConditionEnum {
  TotalMissionTime = "total_mission_time",
  TimeToPatientCritical = "time_to_patient_critical",
  OutOfHospitalCritical = "out_of_hospital_critical",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAbbreviatedMedicalTeamEnum {
  FlightNurseOnly = "flight_nurse_only",
  DoctorRequired = "doctor_required",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAbbreviatedPlannedDepartureEnum {
  Now = "now",
  SpecificTime = "specific_time",
}

/**
 * Mission Asset model
 * @export
 * @interface MissionAssetWithRelated
 */
export interface MissionAssetWithRelated {
  /**
   *
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionAssetWithRelated
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MissionAssetWithRelated
   */
  asset_kind?: MissionAssetWithRelatedAssetKindEnum;
  /**
   * FK reference to Airport for aeroplane
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  home_base_airport_id?: number;
  /**
   * FK reference to Address for helicopter
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  home_base_helipad_id?: number;
  /**
   * FK reference to Address for road_ambulance
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  home_base_address_id?: number;
  /**
   * FK reference to Aircraft
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  aircraft_id?: number;
  /**
   * FK reference to RoadAmbulance
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  road_ambulance_id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionAssetWithRelated
   */
  asset_configuration?: MissionAssetWithRelatedAssetConfigurationEnum;
  /**
   *
   * @type {string}
   * @memberof MissionAssetWithRelated
   */
  comment?: string;
  /**
   *
   * @type {number}
   * @memberof MissionAssetWithRelated
   */
  dashboard_order?: number;
  /**
   *
   * @type {string}
   * @memberof MissionAssetWithRelated
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof MissionAssetWithRelated
   */
  updated_at?: string;
  /**
   *
   * @type {Aircraft}
   * @memberof MissionAssetWithRelated
   */
  aircraft?: Aircraft;
  /**
   *
   * @type {RoadAmbulance}
   * @memberof MissionAssetWithRelated
   */
  road_ambulance?: RoadAmbulance;
}

/**
 * @export
 * @enum {string}
 */
export enum MissionAssetWithRelatedAssetKindEnum {
  Aeroplane = "aeroplane",
  Helicopter = "helicopter",
  RoadAmbulance = "road_ambulance",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionAssetWithRelatedAssetConfigurationEnum {
  Rscu = "rscu",
  NetsN = "nets_n",
  NetsP = "nets_p",
  Sps = "sps",
  Other = "other",
  RscuNoWinch = "rscu_no_winch",
  FixedWing = "fixed_wing",
  Road = "road",
}

/**
 * Mission/Crew link model
 * @export
 * @interface MissionCrew
 */
export interface MissionCrew {
  /**
   *
   * @type {number}
   * @memberof MissionCrew
   */
  id?: number;
  /**
   * FK reference to Crew
   * @type {number}
   * @memberof MissionCrew
   */
  crew_id?: number;
  /**
   * FK reference to Mission
   * @type {number}
   * @memberof MissionCrew
   */
  mission_id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionCrew
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof MissionCrew
   */
  updated_at?: string;
}
/**
 * Missions crew request
 * @export
 * @interface MissionCrewRequest
 */
export interface MissionCrewRequest {
  /**
   *
   * @type {object}
   * @memberof MissionCrewRequest
   */
  mission_crew: object;
}
/**
 * Mission crew response
 * @export
 * @interface MissionCrewResponse
 */
export interface MissionCrewResponse {
  /**
   *
   * @type {MissionCrew}
   * @memberof MissionCrewResponse
   */
  data: MissionCrew;
}
/**
 * Missions request
 * @export
 * @interface MissionRequest
 */
export interface MissionRequest {
  /**
   *
   * @type {object}
   * @memberof MissionRequest
   */
  mission: object;
}
/**
 * Missions response
 * @export
 * @interface MissionResponse
 */
export interface MissionResponse {
  /**
   *
   * @type {MissionWithRelated}
   * @memberof MissionResponse
   */
  data: MissionWithRelated;
}
/**
 * Mission with related data
 * @export
 * @interface MissionWithRelated
 */
export interface MissionWithRelated {
  /**
   *
   * @type {number}
   * @memberof MissionWithRelated
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  status?: MissionWithRelatedStatusEnum;
  /**
   *
   * @type {number}
   * @memberof MissionWithRelated
   */
  created_by_user_id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  completion_reason?: MissionWithRelatedCompletionReasonEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  tasking_type?: MissionWithRelatedTaskingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  patient_priority?: MissionWithRelatedPatientPriorityEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  aviation_risk?: MissionWithRelatedAviationRiskEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  assessment_condition?: MissionWithRelatedAssessmentConditionEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  medical_team?: MissionWithRelatedMedicalTeamEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  planned_departure?: MissionWithRelatedPlannedDepartureEnum;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  planned_departure_at?: string;
  /**
   *
   * @type {boolean}
   * @memberof MissionWithRelated
   */
  is_drop_off_only?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MissionWithRelated
   */
  helicopter_access_required?: boolean;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  estimated_return_to_base_at?: string;
  /**
   *
   * @type {number}
   * @memberof MissionWithRelated
   */
  recall_time?: number;
  /**
   *
   * @type {boolean}
   * @memberof MissionWithRelated
   */
  able_respond_medical_mission?: boolean;
  /**
   *
   * @type {number}
   * @memberof MissionWithRelated
   */
  asset_id?: number;
  /**
   *
   * @type {number}
   * @memberof MissionWithRelated
   */
  mission_asset_id?: number;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  mru_number?: string;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  asset_selection_reason?: string;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  comments?: string;
  /**
   *
   * @type {boolean}
   * @memberof MissionWithRelated
   */
  is_winch_required?: boolean;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof MissionWithRelated
   */
  updated_at?: string;
  /**
   *
   * @type {Asset}
   * @memberof MissionWithRelated
   */
  asset?: Asset;
  /**
   *
   * @type {MissionAssetWithRelated}
   * @memberof MissionWithRelated
   */
  mission_asset?: MissionAssetWithRelated;
  /**
   *
   * @type {Array<Leg>}
   * @memberof MissionWithRelated
   */
  legs?: Array<Leg>;
  /**
   *
   * @type {Array<CrewWithShifts>}
   * @memberof MissionWithRelated
   */
  crew?: Array<CrewWithShifts>;
  /**
   *
   * @type {Array<TaskWithRelated>}
   * @memberof MissionWithRelated
   */
  tasks?: Array<TaskWithRelated>;
}

/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedStatusEnum {
  Draft = "draft",
  Confirmed = "confirmed",
  Complete = "complete",
  Cancelled = "cancelled",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedCompletionReasonEnum {
  HomeBase = "home_base",
  CalledOff = "called_off",
  Retasked = "retasked",
  AircraftUnserviceable = "aircraft_unserviceable",
  Weather = "weather",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedTaskingTypeEnum {
  Primary = "primary",
  Secondary = "secondary",
  SecondaryNets = "secondary_nets",
  Other = "other",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedPatientPriorityEnum {
  P1Immediate = "p1_immediate",
  P1 = "p1",
  P2 = "p2",
  P3 = "p3",
  P4 = "p4",
  P5 = "p5",
  P6 = "p6",
  Na = "na",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedAviationRiskEnum {
  NormalOperations = "normal_operations",
  Caution = "caution",
  HighCaution = "high_caution",
  SafetyCritical = "safety_critical",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedAssessmentConditionEnum {
  TotalMissionTime = "total_mission_time",
  TimeToPatientCritical = "time_to_patient_critical",
  OutOfHospitalCritical = "out_of_hospital_critical",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedMedicalTeamEnum {
  FlightNurseOnly = "flight_nurse_only",
  DoctorRequired = "doctor_required",
}
/**
 * @export
 * @enum {string}
 */
export enum MissionWithRelatedPlannedDepartureEnum {
  Now = "now",
  SpecificTime = "specific_time",
}

/**
 * Missions response
 * @export
 * @interface MissionsResponse
 */
export interface MissionsResponse {
  /**
   *
   * @type {Array<MissionWithRelated>}
   * @memberof MissionsResponse
   */
  data: Array<MissionWithRelated>;
  /**
   *
   * @type {MetaResponse}
   * @memberof MissionsResponse
   */
  meta: MetaResponse;
}
/**
 * Recommendations model
 * @export
 * @interface Recommendation
 */
export interface Recommendation {
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  id?: number;
  /**
   * FK reference to Mission
   * @type {number}
   * @memberof Recommendation
   */
  mission_id?: number;
  /**
   * FK reference to Asset
   * @type {number}
   * @memberof Recommendation
   */
  asset_id?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  cost?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  time_to_patient?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  time_out_of_hospital?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  total_mission_time?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  cost_score?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  time_score?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  weighted_score?: number;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  calc_log?: string;
  /**
   *
   * @type {boolean}
   * @memberof Recommendation
   */
  skip_asset?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Recommendation
   */
  asset_declined?: boolean;
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  asset_decline_reason?: string;
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  recommendation_run_at?: string;
  /**
   *
   * @type {number}
   * @memberof Recommendation
   */
  co2_emissions?: number;
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  updated_at?: string;
}
/**
 *
 * @export
 * @interface RecommendationResponse
 */
export interface RecommendationResponse {
  /**
   *
   * @type {RecommendationWithRelated}
   * @memberof RecommendationResponse
   */
  data: RecommendationWithRelated;
}
/**
 * Recommendations including related data
 * @export
 * @interface RecommendationWithRelated
 */
export interface RecommendationWithRelated {
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  id?: number;
  /**
   * FK reference to Mission
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  mission_id?: number;
  /**
   * FK reference to Asset
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  asset_id?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  cost?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  time_to_patient?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  time_out_of_hospital?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  total_mission_time?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  cost_score?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  time_score?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  weighted_score?: number;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof RecommendationWithRelated
   */
  calc_log?: string;
  /**
   *
   * @type {boolean}
   * @memberof RecommendationWithRelated
   */
  skip_asset?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RecommendationWithRelated
   */
  asset_declined?: boolean;
  /**
   *
   * @type {string}
   * @memberof RecommendationWithRelated
   */
  asset_decline_reason?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendationWithRelated
   */
  recommendation_run_at?: string;
  /**
   *
   * @type {number}
   * @memberof RecommendationWithRelated
   */
  co2_emissions?: number;
  /**
   *
   * @type {string}
   * @memberof RecommendationWithRelated
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof RecommendationWithRelated
   */
  updated_at?: string;
  /**
   *
   * @type {AssetAbbreviated}
   * @memberof RecommendationWithRelated
   */
  asset?: AssetAbbreviated;
  /**
   *
   * @type {MissionAbbreviated}
   * @memberof RecommendationWithRelated
   */
  mission?: MissionAbbreviated;
  /**
   *
   * @type {Array<WarningMessage>}
   * @memberof RecommendationWithRelated
   */
  warning_messages?: Array<WarningMessage>;
}
/**
 * Recommendations response
 * @export
 * @interface RecommendationsResponse
 */
export interface RecommendationsResponse {
  /**
   *
   * @type {Array<RecommendationWithRelated>}
   * @memberof RecommendationsResponse
   */
  data: Array<RecommendationWithRelated>;
  /**
   *
   * @type {MetaResponse}
   * @memberof RecommendationsResponse
   */
  meta: MetaResponse;
}
/**
 * RoadAmbulance model
 * @export
 * @interface RoadAmbulance
 */
export interface RoadAmbulance {
  /**
   *
   * @type {number}
   * @memberof RoadAmbulance
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RoadAmbulance
   */
  call_sign?: string;
  /**
   *
   * @type {number}
   * @memberof RoadAmbulance
   */
  road_ambulance_type_id?: number;
  /**
   *
   * @type {string}
   * @memberof RoadAmbulance
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof RoadAmbulance
   */
  updated_at?: string;
}
/**
 * Multiple RoadAmbulance response
 * @export
 * @interface RoadAmbulancesResponse
 */
export interface RoadAmbulancesResponse {
  /**
   *
   * @type {Array<RoadAmbulance>}
   * @memberof RoadAmbulancesResponse
   */
  data: Array<RoadAmbulance>;
  /**
   *
   * @type {MetaResponse}
   * @memberof RoadAmbulancesResponse
   */
  meta: MetaResponse;
}
/**
 * Shift Model
 * @export
 * @interface Shift
 */
export interface Shift {
  /**
   *
   * @type {number}
   * @memberof Shift
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  start_at?: string;
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  end_at?: string;
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  surge_end_at?: string;
  /**
   * night shift = blue; day shift = green
   * @type {string}
   * @memberof Shift
   */
  colour?: ShiftColourEnum;
  /**
   * FK reference to Asset
   * @type {number}
   * @memberof Shift
   */
  asset_id?: number;
  /**
   * Optional FK reference to Airport
   * @type {number}
   * @memberof Shift
   */
  pickup_airport_id?: number;
  /**
   * Optional FK reference to Helipad
   * @type {number}
   * @memberof Shift
   */
  pickup_helipad_id?: number;
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ShiftColourEnum {
  Blue = "blue",
  Green = "green",
}

/**
 * Shift Crew model
 * @export
 * @interface ShiftCrew
 */
export interface ShiftCrew {
  /**
   * FK reference to Crew
   * @type {number}
   * @memberof ShiftCrew
   */
  crew_id?: number;
  /**
   * FK reference to Shift
   * @type {number}
   * @memberof ShiftCrew
   */
  shift_id?: number;
  /**
   *
   * @type {string}
   * @memberof ShiftCrew
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof ShiftCrew
   */
  updated_at?: string;
}
/**
 * Shift crew request
 * @export
 * @interface ShiftCrewRequest
 */
export interface ShiftCrewRequest {
  /**
   *
   * @type {object}
   * @memberof ShiftCrewRequest
   */
  shift_crew: object;
}
/**
 * Shift crew response
 * @export
 * @interface ShiftCrewResponse
 */
export interface ShiftCrewResponse {
  /**
   *
   * @type {ShiftCrew}
   * @memberof ShiftCrewResponse
   */
  data: ShiftCrew;
}
/**
 * Shift request
 * @export
 * @interface ShiftRequest
 */
export interface ShiftRequest {
  /**
   *
   * @type {object}
   * @memberof ShiftRequest
   */
  shift: object;
}
/**
 * Single Shift response
 * @export
 * @interface ShiftResponse
 */
export interface ShiftResponse {
  /**
   *
   * @type {Shift}
   * @memberof ShiftResponse
   */
  data: Shift;
}
/**
 * Task model
 * @export
 * @interface Task
 */
export interface Task {
  /**
   * Order of mission\'s tasks
   * @type {string}
   * @memberof Task
   */
  position?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  task?: TaskTaskEnum;
  /**
   * FK reference to Mission
   * @type {number}
   * @memberof Task
   */
  mission_id?: number;
  /**
   * FK reference to Hospital
   * @type {number}
   * @memberof Task
   */
  from_hospital_id?: number;
  /**
   *
   * @type {number}
   * @memberof Task
   */
  from_lat?: number;
  /**
   *
   * @type {number}
   * @memberof Task
   */
  from_long?: number;
  /**
   * FK reference to Hospital
   * @type {number}
   * @memberof Task
   */
  to_hospital_id?: number;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TaskTaskEnum {
  PatientTransport = "patient_transport",
  MedicalTeamDropOff = "medical_team_drop_off",
}

/**
 * Task with related
 * @export
 * @interface TaskWithRelated
 */
export interface TaskWithRelated {
  /**
   * Order of mission\'s tasks
   * @type {string}
   * @memberof TaskWithRelated
   */
  position?: string;
  /**
   *
   * @type {string}
   * @memberof TaskWithRelated
   */
  task?: TaskWithRelatedTaskEnum;
  /**
   * FK reference to Mission
   * @type {number}
   * @memberof TaskWithRelated
   */
  mission_id?: number;
  /**
   * FK reference to Hospital
   * @type {number}
   * @memberof TaskWithRelated
   */
  from_hospital_id?: number;
  /**
   *
   * @type {number}
   * @memberof TaskWithRelated
   */
  from_lat?: number;
  /**
   *
   * @type {number}
   * @memberof TaskWithRelated
   */
  from_long?: number;
  /**
   * FK reference to Hospital
   * @type {number}
   * @memberof TaskWithRelated
   */
  to_hospital_id?: number;
  /**
   *
   * @type {string}
   * @memberof TaskWithRelated
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof TaskWithRelated
   */
  updated_at?: string;
  /**
   *
   * @type {HospitalAbbreviated}
   * @memberof TaskWithRelated
   */
  from_hospital?: HospitalAbbreviated;
  /**
   *
   * @type {HospitalAbbreviated}
   * @memberof TaskWithRelated
   */
  to_hospital?: HospitalAbbreviated;
}

/**
 * @export
 * @enum {string}
 */
export enum TaskWithRelatedTaskEnum {
  PatientTransport = "patient_transport",
  MedicalTeamDropOff = "medical_team_drop_off",
}

/**
 * TimeZone Model
 * @export
 * @interface TimeZone
 */
export interface TimeZone {
  /**
   *
   * @type {number}
   * @memberof TimeZone
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TimeZone
   */
  time_zone?: string;
  /**
   * Placement order on the Dashboard (if not null)
   * @type {number}
   * @memberof TimeZone
   */
  position?: number;
  /**
   *
   * @type {string}
   * @memberof TimeZone
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof TimeZone
   */
  updated_at?: string;
}
/**
 * Single TimeZone response
 * @export
 * @interface TimeZoneResponse
 */
export interface TimeZoneResponse {
  /**
   *
   * @type {TimeZone}
   * @memberof TimeZoneResponse
   */
  data: TimeZone;
}
/**
 * Multiple TimeZones response
 * @export
 * @interface TimeZonesResponse
 */
export interface TimeZonesResponse {
  /**
   *
   * @type {Array<TimeZone>}
   * @memberof TimeZonesResponse
   */
  data: Array<TimeZone>;
  /**
   *
   * @type {MetaResponse}
   * @memberof TimeZonesResponse
   */
  meta: MetaResponse;
}
/**
 * Unavailability model
 * @export
 * @interface Unavailability
 */
export interface Unavailability {
  /**
   *
   * @type {number}
   * @memberof Unavailability
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Unavailability
   */
  asset_id?: number;
  /**
   *
   * @type {string}
   * @memberof Unavailability
   */
  start_at?: string;
  /**
   *
   * @type {string}
   * @memberof Unavailability
   */
  end_at?: string;
  /**
   *
   * @type {string}
   * @memberof Unavailability
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Unavailability
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Unavailability
   */
  updated_at?: string;
}
/**
 * Unavailability request
 * @export
 * @interface UnavailabilityRequest
 */
export interface UnavailabilityRequest {
  /**
   *
   * @type {object}
   * @memberof UnavailabilityRequest
   */
  unavailability: object;
}
/**
 * Unavailability response
 * @export
 * @interface UnavailabilityResponse
 */
export interface UnavailabilityResponse {
  /**
   *
   * @type {Unavailability}
   * @memberof UnavailabilityResponse
   */
  data: Unavailability;
}
/**
 * User Model
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_observer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_tasker?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_manager?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  position?: UserPositionEnum;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_rostered?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  time_zone?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UserPositionEnum {
  Drc = "drc",
  Nets = "nets",
  Ops = "ops",
  Rltc = "rltc",
  Src = "src",
}

/**
 * User request
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
  /**
   *
   * @type {object}
   * @memberof UserRequest
   */
  user: object;
}
/**
 * Single User response
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {User}
   * @memberof UserResponse
   */
  data: User;
}
/**
 * Multiple Users response
 * @export
 * @interface UsersResponse
 */
export interface UsersResponse {
  /**
   *
   * @type {Array<User>}
   * @memberof UsersResponse
   */
  data: Array<User>;
  /**
   *
   * @type {MetaResponse}
   * @memberof UsersResponse
   */
  meta: MetaResponse;
}
/**
 * WarningMessage Model
 * @export
 * @interface WarningMessage
 */
export interface WarningMessage {
  /**
   *
   * @type {number}
   * @memberof WarningMessage
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof WarningMessage
   */
  warning_kind?: WarningMessageWarningKindEnum;
  /**
   *
   * @type {string}
   * @memberof WarningMessage
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof WarningMessage
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof WarningMessage
   */
  updated_at?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum WarningMessageWarningKindEnum {
  Warning = "warning",
  Critical = "critical",
}
