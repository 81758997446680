import { Stack, Typography } from "@mui/material";
import { FormRadioGroup } from "component/FormRadioGroup";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { COLORS } from "style";
import { CustomBadge } from "component/CustomBadge";
import { pathWithSearchParams } from "utility/router";

const taskingTypes = [
  { label: "Primary", value: "primary", disabled: false },
  { label: "Secondary", value: "secondary", disabled: false },
  { label: "NETS", value: "nets", disabled: false },
  { label: "Other", value: "other" },
];

export const CreateMissionLayout = () => {
  const navigate = useNavigate();
  const match = useMatch("/main/dashboard/mission/create/:taskingType");
  const handleTaskingTypeChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    navigate(pathWithSearchParams(value));
  };

  return (
    <Stack sx={{ width: "100%" }}>
      <Stack
        sx={{
          flexGrow: 1,
          p: 4,
          pb: 0,
          border: `2px solid ${COLORS.WHITE_SMOKE}`,
          width: "100%",
          borderRadius: "20px",
          borderBottom: 0,
          borderEndEndRadius: 0,
          borderEndStartRadius: 0,
        }}
      >
        <Stack sx={{ width: "max-content", marginLeft: "10%", gap: 4 }}>
          <Stack sx={{ gap: 1 }}>
            <Stack direction={"row"} sx={{ alignItems: "center", gap: 2 }}>
              <Typography variant="h1">New mission setup</Typography> <CustomBadge>New</CustomBadge>
            </Stack>
            <Typography>Select the mission task parameters</Typography>
          </Stack>
          <FormRadioGroup
            fullWidth
            required
            row
            items={taskingTypes}
            value={match?.params.taskingType ?? ""}
            label="Tasking type"
            onChange={handleTaskingTypeChange}
          />
        </Stack>
      </Stack>
      <Outlet />
    </Stack>
  );
};
