import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, InputAdornment, MenuItem, Stack, Typography } from "@mui/material";
import { CustomDialog, CustomDialogTitle } from "component/CustomDialog";
import { ErrorAlert } from "component/ErrorAlert";
import { FormSelect } from "component/FormSelect";
import { FormTextInput } from "component/FormTextInput";
import { ArrowDownRightIcon, ArrowUpRightIcon } from "component/Icons";
import { LEG_TYPES } from "constant";
import { Controller, useForm } from "react-hook-form";
import { useFetcher, useParams } from "react-router-dom";
import { LegStageEnum } from "type/model/api";
import { CreateMissionLegFormProps, createMissionLegSchema } from "type/schema";
import { transformEnumValue } from "utility/transformer";

interface Props {
  onClose(): void;
}

export const AddMissionLegDialog = ({ onClose }: Props) => {
  const params = useParams();
  const fetcher = useFetcher();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<CreateMissionLegFormProps>({
    resolver: zodResolver(createMissionLegSchema),
    mode: "all",
  });

  const onSubmit = (values: CreateMissionLegFormProps) => {
    const formData = new FormData();
    const leg = {
      leg_type: values.legType,
      from_text: values.fromText,
      to_text: values.toText,
      stage: values.stage,
      visible: true,
    };

    formData.append("action", "create");
    formData.append("leg", JSON.stringify(leg));

    fetcher.submit(formData, {
      action: `/main/dashboard/mission/edit/${params.missionId}/legs`,
      method: "post",
    });
  };

  return (
    <CustomDialog open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CustomDialogTitle onClose={onClose} title="Add mission leg" />
        <DialogContent dividers sx={{ minWidth: 560 }}>
          <Stack sx={{ gap: 2 }}>
            <Controller
              name="legType"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormSelect
                  label="Type"
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  items={LEG_TYPES.map((item) => ({
                    value: item,
                    element: (
                      <MenuItem key={item} value={item} autoFocus={false}>
                        <Typography variant="body2">{transformEnumValue(item)}</Typography>
                      </MenuItem>
                    ),
                    readOnlyText: transformEnumValue(item),
                  }))}
                />
              )}
            />
            <Controller
              name="fromText"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormTextInput
                  label="From"
                  placeholder="Enter the mission leg departing location"
                  fullWidth
                  required
                  value={value ?? ""}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  type={"text"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ArrowUpRightIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="toText"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormTextInput
                  label="To"
                  placeholder="Enter the mission leg arrival destination"
                  fullWidth
                  required
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  type={"text"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ArrowDownRightIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="stage"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormSelect
                  label="Stage"
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  items={Object.values(LegStageEnum).map((item) => ({
                    value: item,
                    element: (
                      <MenuItem key={item} value={item} autoFocus={false}>
                        <Typography variant="body2">{transformEnumValue(item)}</Typography>
                      </MenuItem>
                    ),
                    readOnlyText: transformEnumValue(item),
                  }))}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
          <LoadingButton
            size="large"
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </CustomDialog>
  );
};
