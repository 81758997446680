import { Stack, Typography, TypographyProps } from "@mui/material";
import { COLORS } from "style";
import { MissionWithRelatedStatusEnum } from "type/model/api";
import { MissionLeg } from "./MissionLeg";
import { isAfter } from "date-fns";
import orderBy from "lodash/orderBy";
import findLastIndex from "lodash/findLastIndex";
import findLast from "lodash/findLast";
import { MissionView } from "type/model/view";

interface Props {
  mission: MissionView;
}

const StageLabel = (props: TypographyProps) => {
  return <Typography variant="subtitle1" sx={{ color: COLORS.SHUTTER_BLUE }} {...props} />;
};

const containerStyle = { gap: 1, alignItems: "flex-start" };

export const MissionStage = ({ mission }: Props) => {
  const now = Date.now();

  const missionLegs = orderBy(mission.legs, ["legOrder"]) ?? [];

  const legsCount = missionLegs.length;

  if (mission.status === MissionWithRelatedStatusEnum.Draft && mission.taskingType === "other") {
    return <Typography variant="body2">Draft (Other)</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Draft && mission.taskingType === "secondary") {
    return <Typography variant="body2">Draft (Secondary)</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Draft && mission.taskingType === "secondary_nets") {
    return <Typography variant="body2">Draft (Nets)</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Draft && mission.taskingType === "primary") {
    return <Typography variant="body2">Draft (Primary)</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Cancelled) {
    return <Typography variant="body2">Cancelled</Typography>;
  }

  if (mission.status === MissionWithRelatedStatusEnum.Complete) {
    if (legsCount === 0) {
      return null;
    }

    if (legsCount === 1 && missionLegs[0].visible === true) {
      return (
        <Stack sx={containerStyle}>
          <StageLabel>Last leg</StageLabel>
          <MissionLeg missionLeg={missionLegs[0]} />
        </Stack>
      );
    }

    const firstLeg = missionLegs.find((leg) => leg.visible === true) || null;
    const lastLeg = findLast(missionLegs, (leg) => leg.visible === true) || null;
    return (
      <>
        {firstLeg && (
          <Stack sx={containerStyle}>
            <StageLabel>First leg</StageLabel>
            <MissionLeg missionLeg={firstLeg} />
          </Stack>
        )}
        {lastLeg && (
          <Stack sx={containerStyle}>
            <StageLabel>Last leg</StageLabel>
            <MissionLeg missionLeg={lastLeg} />
          </Stack>
        )}
      </>
    );
  }

  if (legsCount === 0) {
    return (
      <Stack sx={containerStyle}>
        <StageLabel>Upcoming first leg</StageLabel>
        <MissionLeg missionLeg={null} />
      </Stack>
    );
  }

  // Current leg will always be visible so no need to check
  const currentLegIndex = findLastIndex(missionLegs, (leg) => Boolean(leg.isCurrent));

  if (currentLegIndex > -1) {
    const currentLeg = missionLegs[currentLegIndex];
    const upcomingLeg = missionLegs.slice(currentLegIndex + 1).find((leg) => leg.visible === true);
    const isSARTimeReached = Boolean(currentLeg.sarTime && isAfter(now, currentLeg.sarTime));

    return (
      <Stack sx={containerStyle}>
        <Stack sx={containerStyle}>
          <StageLabel>Current leg</StageLabel>
          <Stack>
            <MissionLeg missionLeg={currentLeg} isLegCurrent isSARTimeReached={isSARTimeReached} />
            {isSARTimeReached ? (
              <Typography variant="subtitle1" color={COLORS.KHMER_CURRY}>
                SAR Time has been reached
              </Typography>
            ) : null}
          </Stack>
        </Stack>

        {upcomingLeg ? (
          <Stack sx={containerStyle}>
            <StageLabel>Upcoming leg</StageLabel>
            <MissionLeg missionLeg={upcomingLeg} />
          </Stack>
        ) : null}
      </Stack>
    );
  }

  // When theres no current leg
  const upcomingLegIndex = missionLegs.findIndex((leg) => leg.visible === true && !leg.enteredStartTime);
  if (upcomingLegIndex > -1) {
    const upcomingLeg = missionLegs[upcomingLegIndex];
    const isFirstVisibleLeg = missionLegs.findIndex((leg) => leg.visible === true) === upcomingLegIndex;

    return (
      <Stack sx={containerStyle}>
        <StageLabel>Upcoming {isFirstVisibleLeg ? "first" : ""} leg</StageLabel>
        <MissionLeg missionLeg={upcomingLeg} />
      </Stack>
    );
  }

  // Need to test this one, others have been tested
  const lastLeg = findLast(missionLegs, (leg) => leg.visible === true) || null;
  return (
    lastLeg && (
      <Stack sx={containerStyle}>
        <StageLabel>Last leg</StageLabel>
        <MissionLeg missionLeg={lastLeg} />
      </Stack>
    )
  );
};
