import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AssetMissions } from "./AssetMissions";
import { AssetView, AssetViewStatus, MissionView } from "type/model/view";
import { AssetStatus, AssetIdentifier } from "module/common";
import { useMemo } from "react";
import orderBy from "lodash/orderBy";
import { Stack } from "@mui/material";
import { MissionWithRelatedStatusEnum, User } from "type/model/api";
import { useRouteLoaderData } from "react-router-dom";
import { AssetComment } from "./AssetComment";

interface Props {
  assets: AssetView[];
  missions: MissionView[];
}

export const AssetsTable = ({ assets, missions }: Props) => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const orderedAssets = useMemo(() => {
    return orderBy(assets, (asset) => {
      return `${asset.status === AssetViewStatus.Online ? 0 : 1} ${
        missions.some((mission) => mission.assetId === asset.id) ? 0 : 1
      } ${asset.dashboardOrder.toString(10).padStart(6)}`;
    });
  }, [assets, missions]);

  const orderedMissions = useMemo(() => {
    return orderBy(
      missions,
      (mission) => {
        let missionStatusOrder;
        switch (mission.status) {
          case MissionWithRelatedStatusEnum.Draft:
            missionStatusOrder = 0;
            break;
          case MissionWithRelatedStatusEnum.Confirmed:
            missionStatusOrder = 1;
            break;
          case MissionWithRelatedStatusEnum.Complete:
            missionStatusOrder = 2;
            break;
          case MissionWithRelatedStatusEnum.Cancelled:
            missionStatusOrder = 3;
            break;
        }

        const lastLegEndTime = orderBy(mission.legs, "endTime", "desc")?.[0]?.endTime?.toISOString() ?? "";

        return [`${missionStatusOrder} ${mission.legs.length === 0 ? 0 : 1}`, lastLegEndTime];
      },
      ["asc", "desc"]
    );
  }, [missions]);

  const missionsWithoutAsset = orderedMissions.filter((mission) => mission.assetId === null);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Assets</TableCell>
            <TableCell>Asset Status</TableCell>
            <TableCell sx={{ width: 250, px: 2 }}>Crew</TableCell>
            <TableCell sx={{ flexGrow: 1, px: 2 }} colSpan={2}>
              Stage
            </TableCell>
            <TableCell>Asset comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedAssets.map((asset) => {
            const assetMissions = orderedMissions.filter((mission) => mission.assetId === asset.id);
            return (
              <TableRow key={asset.id} sx={{ verticalAlign: "top", "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <AssetIdentifier asset={asset} />
                </TableCell>
                <TableCell>
                  <Stack sx={{ alignItems: "flex-start", maxWidth: 220 }}>
                    <AssetStatus asset={asset} />
                  </Stack>
                </TableCell>
                <TableCell colSpan={3} sx={{ p: 0 }}>
                  <AssetMissions asset={asset} assetMissions={assetMissions} />
                </TableCell>
                <TableCell sx={{ maxWidth: 220 }}>
                  <AssetComment asset={asset} readOnly={!(currentUser.is_manager || currentUser.is_tasker)} />
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow
            key="Null missions"
            sx={{ verticalAlign: "top", "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <AssetIdentifier />
            </TableCell>
            <TableCell>
              <Stack sx={{ alignItems: "flex-start", maxWidth: 220 }}>
                <AssetStatus />
              </Stack>
            </TableCell>
            <TableCell colSpan={3} sx={{ p: 0 }}>
              <AssetMissions assetMissions={missionsWithoutAsset} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
