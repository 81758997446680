import * as api from "api";
import { Airport, Asset } from "type/model/api";
import { AssetDetailAutocomplete } from "./AssetDetailAutocomplete";
import { Identifiable } from "type/model/view";
import { QueryFunction } from "@tanstack/react-query";

interface Props {
  value: number | null;
}

export const AirportSelect = ({ value }: Props) => {
  const queryKey = ["airports"];
  const queryFn = api.fetchAirports as QueryFunction<{ data: Identifiable[] }>;

  const getLabel = (airport: Airport) => {
    return `${airport.name} - ${airport.code}`;
  };

  const getUpdatePayload = (selection: Identifiable) => {
    return { home_base_airport_id: selection.id, home_base_helipad_id: null } as unknown as Partial<Asset>;
  };

  return (
    <AssetDetailAutocomplete
      placeholder="Select an airport"
      queryKey={queryKey}
      queryFn={queryFn}
      getLabel={getLabel}
      getUpdatePayload={getUpdatePayload}
      value={value || null}
    />
  );
};
