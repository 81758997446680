import { isAfter, isBefore, parseISO } from "date-fns";
import {
  Asset,
  AssetWithRelated,
  AssetWithRelatedAssetConfigurationEnum,
  AssetWithRelatedAssetKindEnum,
  MissionAssetWithRelatedAssetKindEnum,
  CrewWithShifts,
  CrewWithShiftsCrewRoleEnum,
  Leg,
  MissionWithRelated,
  MissionWithRelatedPatientPriorityEnum,
  MissionAssetWithRelated,
  Shift,
  UserPositionEnum,
} from "type/model/api";
import {
  AssetUnavailabilityView,
  AssetView,
  AssetViewStatus,
  CrewView,
  MissionAssetView,
  MissionLegView,
  MissionView,
  ShiftView,
} from "type/model/view";
import upperFirst from "lodash/upperFirst";
import orderBy from "lodash/orderBy";
import { ORDERED_CREW_ROLES } from "constant";
import { clearSeconds } from "utility/dateTime";
import { utcToZonedTime } from "date-fns-tz";

const getCallSign = (asset: AssetWithRelated | Asset | MissionAssetWithRelated): string | null => {
  switch (asset.asset_kind) {
    case AssetWithRelatedAssetKindEnum.RoadAmbulance:
      return asset.road_ambulance?.call_sign ?? null;
    case AssetWithRelatedAssetKindEnum.Helicopter:
    case MissionAssetWithRelatedAssetKindEnum.Helicopter:
    case AssetWithRelatedAssetKindEnum.Aeroplane:
    case MissionAssetWithRelatedAssetKindEnum.Aeroplane:
      return asset.aircraft?.call_sign ?? null;
    default:
      return null;
  }
};

const getRegistration = (asset: AssetWithRelated | Asset | MissionAssetWithRelated): string | null => {
  switch (asset.asset_kind) {
    case AssetWithRelatedAssetKindEnum.Helicopter:
    case MissionAssetWithRelatedAssetKindEnum.Helicopter:
    case AssetWithRelatedAssetKindEnum.Aeroplane:
    case MissionAssetWithRelatedAssetKindEnum.Aeroplane:
      return asset.aircraft?.registration ?? null;
    default:
      return null;
  }
};

export const transformShiftView = (shift: Shift): ShiftView => {
  return {
    id: shift.id!,
    name: shift.name ?? null,
    startTime: shift.start_at ? parseISO(shift.start_at) : null,
    endTime: shift.end_at ? parseISO(shift.end_at) : null,
    surgeEndTime: shift.surge_end_at ? parseISO(shift.surge_end_at) : null,
    color: shift.colour ?? null,
    assetId: shift.asset_id ?? null,
  };
};

export const transformCrewView = (crew: CrewWithShifts): CrewView => {
  return {
    id: crew.id!,
    firstName: crew.first_name ?? null,
    lastName: crew.last_name ?? null,
    role: crew.crew_role,
    shifts: crew.shifts?.map(transformShiftView) ?? [],
    shift_ids: crew.shift_ids,
  };
};

const sortedCrew = (crew: CrewWithShifts[] | undefined) =>
  crew
    ? orderBy(crew, ({ crew_role }) => [crew_role ? ORDERED_CREW_ROLES.indexOf(crew_role) : 1000, "first_name"])
    : [];

export const transformAssetView = (asset: AssetWithRelated): AssetView => {
  const now = Date.now();
  const unavailability =
    asset.unavailabilities?.map<AssetUnavailabilityView>((unavailability) => ({
      id: unavailability.id!,
      startTime: unavailability.start_at ? parseISO(unavailability.start_at) : null,
      endTime: unavailability.end_at ? parseISO(unavailability.end_at) : null,
      comment: unavailability.comment ?? null,
    }))?.[0] ?? null;
  const status =
    unavailability &&
    (!unavailability.startTime || isAfter(now, unavailability.startTime)) &&
    (!unavailability.endTime || isBefore(now, unavailability.endTime))
      ? AssetViewStatus.Offline
      : AssetViewStatus.Online;

  return {
    id: asset.id!,
    name: asset.name ?? null,
    status,
    assetKind: asset.asset_kind ?? null,
    comment: asset.comment ?? null,
    callSign: getCallSign(asset),
    registration: getRegistration(asset),
    dashboardOrder: asset.dashboard_order ?? 0,
    crew: sortedCrew(asset.crew).map(transformCrewView),
    unavailability,
    shifts: asset.shifts?.map(transformShiftView) ?? [],
    aircraftId: asset.aircraft_id ?? null,
    roadAmbulanceId: asset.road_ambulance_id ?? null,
    homeBaseAddressId: asset.home_base_address_id ?? null,
    homeBaseAirportId: asset.home_base_airport_id ?? null,
    homeBaseHelipadId: asset.home_base_helipad_id ?? null,
    assetConfiguration: asset.asset_configuration ?? null,
  };
};

export const transformMissionAsset = (missionAsset: MissionAssetWithRelated): MissionAssetView => {
  return {
    id: missionAsset.id!,
    name: missionAsset.name ?? null,
    assetKind: missionAsset.asset_kind ?? null,
    comment: missionAsset.comment ?? null,
    callSign: getCallSign(missionAsset),
    registration: getRegistration(missionAsset),
    dashboardOrder: missionAsset.dashboard_order ?? 0,
    aircraftId: missionAsset.aircraft_id ?? null,
    roadAmbulanceId: missionAsset.road_ambulance_id ?? null,
    homeBaseAddressId: missionAsset.home_base_address_id ?? null,
    homeBaseAirportId: missionAsset.home_base_airport_id ?? null,
    homeBaseHelipadId: missionAsset.home_base_helipad_id ?? null,
    assetConfiguration: missionAsset.asset_configuration ?? null,
  };
};

export const transformMissionLeg = (leg: Leg): MissionLegView => {
  const startTimeStr = leg.entered_start_at;
  const endTimeStr = leg.entered_end_at;
  const calcStartTimeStr = leg.calculated_start_at;
  const calcEndTimeStr = leg.calculated_end_at;

  const startTime = startTimeStr ? clearSeconds(parseISO(startTimeStr)) : null;
  const endTime = endTimeStr ? clearSeconds(parseISO(endTimeStr)) : null;
  const calcStartTime = calcStartTimeStr ? clearSeconds(parseISO(calcStartTimeStr)) : null;
  const calcEndTime = calcEndTimeStr ? clearSeconds(parseISO(calcEndTimeStr)) : null;
  const sarTime = leg.sar_at ? clearSeconds(parseISO(leg.sar_at)) : null;

  return {
    visible: leg.visible,
    id: leg.id!,
    enteredStartTime: leg.entered_start_at ? parseISO(leg.entered_start_at) : null,
    enteredEndTime: leg.entered_end_at ? parseISO(leg.entered_end_at) : null,
    startTime,
    endTime,
    calcStartTime,
    calcEndTime,
    sarTime,
    toLocation: leg.location_to_id ?? null,
    fromLocation: leg.location_from_id ?? null,
    isCurrent: leg.is_current ?? null,
    fromText: leg.from_text ?? null,
    toText: leg.to_text ?? null,
    legType: leg.leg_type ?? null,
    stage: leg.stage ?? null,
    pob: leg.pob ?? null,
    legTime: leg.minutes ?? null,
    legOrder: leg.leg_order ?? 0,
  };
};

export const convertMissionLegTimes =
  (timeZone: string) =>
  (leg: MissionLegView): MissionLegView => {
    const timeZonedStartTime = leg.startTime ? utcToZonedTime(leg.startTime, timeZone) : null;
    const timeZonedEndTime = leg.endTime ? utcToZonedTime(leg.endTime, timeZone) : null;
    const timeZonedSarTime = leg.sarTime ? utcToZonedTime(leg.sarTime, timeZone) : null;

    return {
      ...leg,
      startTime: timeZonedStartTime,
      endTime: timeZonedEndTime,
      sarTime: timeZonedSarTime,
    };
  };

export const transformMissionView = (mission: MissionWithRelated): MissionView => {
  const plannedDepartureTime = mission.planned_departure_at
    ? clearSeconds(parseISO(mission.planned_departure_at))
    : null;
  const estimatedReturnToBaseTime = mission.estimated_return_to_base_at
    ? clearSeconds(parseISO(mission.estimated_return_to_base_at))
    : null;

  return {
    id: mission.id!,
    createBy: mission.created_by_user_id ?? null,
    status: mission.status ?? null,
    assetId: mission.asset_id!,
    crew: sortedCrew(mission.crew).map(transformCrewView),
    legs: mission.legs ? mission.legs.map(transformMissionLeg) : [],
    missionAsset: mission.mission_asset ? transformMissionAsset(mission.mission_asset) : null,
    assessmentCondition: mission.assessment_condition ?? null,
    taskingType: mission.tasking_type ?? null,
    patientPriority: mission.patient_priority ?? null,
    riskScore: mission.aviation_risk ?? null,
    mru: mission.mru_number ?? null,
    comments: mission.comments ?? null,
    plannedDepartureTime,
    estimatedReturnToBaseTime,
    isMedicalMissionRespondAvailable: mission.able_respond_medical_mission ?? null,
    recallTime: mission.recall_time ?? null,
  };
};

const transformAcronyms = (text: string | null | undefined) => {
  if (!text) {
    return text;
  }

  if (
    (
      [
        CrewWithShiftsCrewRoleEnum.Aco,
        UserPositionEnum.Drc,
        UserPositionEnum.Nets,
        UserPositionEnum.Ops,
        UserPositionEnum.Rltc,
        UserPositionEnum.Src,
        MissionWithRelatedPatientPriorityEnum.Na,
        AssetWithRelatedAssetConfigurationEnum.Sps,
        AssetWithRelatedAssetConfigurationEnum.Rscu,
      ] as string[]
    ).includes(text)
  ) {
    return text.toUpperCase();
  }

  return text;
};

export const transformEnumValue = (
  enumValue: string | null | undefined,
  transformMethod: "upperFirst" | "upperEach" | "none" = "upperFirst"
) => {
  if (!enumValue) {
    return null;
  }

  const splitWords = enumValue.split("_").map(transformAcronyms);
  switch (transformMethod) {
    case "upperFirst":
      return upperFirst(splitWords.join(" "));
    case "upperEach":
      return splitWords.map((value) => upperFirst(value!)).join(" ");
    case "none":
      return splitWords.join(" ");
  }
};
