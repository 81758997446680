import { Stack, Typography } from "@mui/material";
import { AssetView } from "type/model/view";

interface Props {
  asset?: AssetView | undefined;
}

export const AssetIdentifier = ({ asset }: Props) => {
  if (!asset) {
    return <>No asset assigned</>;
  }

  return (
    <Stack sx={{ whiteSpace: "nowrap" }}>
      <Typography variant="body2">{asset.name}</Typography>
      {asset.callSign ? <Typography variant="body2">{asset.callSign}</Typography> : null}
      {asset.registration ? <Typography variant="body2">{asset.registration}</Typography> : null}
    </Stack>
  );
};
