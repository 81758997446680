import { ReactComponent as Eye } from "asset/svg/eye.svg";
import { ReactComponent as EyeOff } from "asset/svg/eye-off.svg";
import { ReactComponent as Key } from "asset/svg/key.svg";
import { ReactComponent as Email } from "asset/svg/email.svg";
import { ReactComponent as Logout } from "asset/svg/logout.svg";
import { ReactComponent as ChevronDown } from "asset/svg/chevron-down.svg";
import { ReactComponent as ChevronRight } from "asset/svg/chevron-right.svg";
import { ReactComponent as ArrowLeft } from "asset/svg/arrow-left.svg";
import { ReactComponent as Layer } from "asset/svg/layer.svg";
import { ReactComponent as Edit } from "asset/svg/edit.svg";
import { ReactComponent as Timer } from "asset/svg/timer.svg";
import { ReactComponent as Clock } from "asset/svg/clock.svg";
import { ReactComponent as Delete } from "asset/svg/delete.svg";
import { ReactComponent as AlignJustify } from "asset/svg/align-justify.svg";
import { ReactComponent as Apps } from "asset/svg/apps.svg";
import { ReactComponent as Dashboard3 } from "asset/svg/dashboard-3.svg";
import { ReactComponent as DoubleArrowUp } from "asset/svg/double-arrow-up.svg";
import { ReactComponent as Support } from "asset/svg/support.svg";
import { ReactComponent as UserGroup } from "asset/svg/user-group.svg";
import { ReactComponent as User } from "asset/svg/user.svg";
import { ReactComponent as Clear } from "asset/svg/clear.svg";
import { ReactComponent as ArrowDownRight } from "asset/svg/arrow-down-right.svg";
import { ReactComponent as ArrowUpRight } from "asset/svg/arrow-up-right.svg";
import { ReactComponent as DateRange } from "asset/svg/date-range.svg";
import { ReactComponent as Ambulance } from "asset/svg/ambulance.svg";
import { ReactComponent as Search } from "asset/svg/search.svg";

type IconSVGProps = React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
};

export type IconType = React.FunctionComponent<IconSVGProps>;

const defaultProps: IconSVGProps = {
  width: 20,
  height: 20,
};

export const EyeIcon: IconType = (props) => <Eye {...defaultProps} {...props} />;
export const EyeOffIcon: IconType = (props) => <EyeOff {...defaultProps} {...props} />;
export const KeyIcon: IconType = (props) => <Key {...defaultProps} {...props} />;
export const EmailIcon: IconType = (props) => <Email {...defaultProps} {...props} />;
export const LogoutIcon: IconType = (props) => <Logout {...defaultProps} {...props} />;
export const ChevronDownIcon: IconType = (props) => <ChevronDown {...defaultProps} {...props} />;
export const ChevronRightIcon: IconType = (props) => <ChevronRight {...defaultProps} {...props} />;
export const ArrowLeftIcon: IconType = (props) => <ArrowLeft {...defaultProps} {...props} />;
export const LayerIcon: IconType = (props) => <Layer {...defaultProps} {...props} />;
export const EditIcon: IconType = (props) => <Edit {...defaultProps} {...props} />;
export const TimerIcon: IconType = (props) => <Timer {...defaultProps} {...props} />;
export const ClockIcon: IconType = (props) => <Clock {...defaultProps} {...props} />;
export const DeleteIcon: IconType = (props) => <Delete {...defaultProps} {...props} />;
export const AlignJustifyIcon: IconType = (props) => <AlignJustify {...defaultProps} {...props} />;
export const AppsIcon: IconType = (props) => <Apps {...defaultProps} {...props} />;
export const Dashboard3Icon: IconType = (props) => <Dashboard3 {...defaultProps} {...props} />;
export const DoubleArrowUpIcon: IconType = (props) => <DoubleArrowUp {...defaultProps} {...props} />;
export const SupportIcon: IconType = (props) => <Support {...defaultProps} {...props} />;
export const UserGroupIcon: IconType = (props) => <UserGroup {...defaultProps} {...props} />;
export const UserIcon: IconType = (props) => <User {...defaultProps} {...props} />;
export const ClearIcon: IconType = (props) => <Clear {...defaultProps} {...props} />;
export const ArrowDownRightIcon: IconType = (props) => <ArrowDownRight {...defaultProps} {...props} />;
export const ArrowUpRightIcon: IconType = (props) => <ArrowUpRight {...defaultProps} {...props} />;
export const DateRangeIcon: IconType = (props) => <DateRange {...defaultProps} {...props} />;
export const AmbulanceIcon: IconType = (props) => <Ambulance {...defaultProps} {...props} />;
export const SearchIcon: IconType = (props) => <Search {...defaultProps} {...props} />;
