import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputAdornment, MenuItem, Stack, Typography } from "@mui/material";
import { ErrorAlert } from "component/ErrorAlert";
import { FormDateTimePicker } from "component/FormDateTimePicker";
import { FormRadioGroup } from "component/FormRadioGroup";
import { FormSelect } from "component/FormSelect";
import { AlignJustifyIcon, ChevronDownIcon, Dashboard3Icon, DoubleArrowUpIcon } from "component/Icons";
import * as api from "api";
import { CustomSelect } from "../../component/CustomSelect";
import {
  DEFAULT_TIME_ZONE,
  MEDICAL_TEAM_OPTIONS,
  PLANNED_DEPARTURE_OPTIONS,
  PRIORITY_SELECT_ITEMS,
  RISK_SCORE_SELECT_ITEMS,
  SPECIAL_CONDITION_SELECT_ITEMS,
} from "constant";
import { format } from "date-fns-tz";
import { enAU } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ActionFunction, redirect, useFetcher, useRouteLoaderData } from "react-router-dom";
import { COLORS } from "style";
import {
  Hospital,
  Mission,
  MissionAssessmentConditionEnum,
  MissionMedicalTeamEnum,
  MissionPlannedDepartureEnum,
  MissionStatusEnum,
  MissionTaskingTypeEnum,
  User,
} from "type/model/api";
import { CreateSecondaryMissionFormProps, createSecondaryMissionSchema } from "type/schema";
import { CustomInputLabel } from "component/CustomInputLabel";
import { pathWithSearchParams } from "utility/router";

export const createSecMissionAction: ActionFunction = async ({ request }) => {
  const formValues = await request.formData();
  const missionValues = formValues.get("mission");
  if (missionValues && typeof missionValues === "string") {
    try {
      const response = await api.createMission({ mission: JSON.parse(missionValues) });
      const missionId = response.data.data.id;
      return redirect(pathWithSearchParams(`/main/dashboard/mission/${missionId}/recommendations`));
    } catch (error) {
      if (error instanceof Error) {
        return { formError: error.message };
      }
      return { formError: "Unexpected error!" };
    }
  } else {
    return { formError: "Invalid form data format!" };
  }
};

export const CreateSecondaryMission = () => {
  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const currentUserTimeZone = currentUser.time_zone || DEFAULT_TIME_ZONE;
  const currentUserTimeZoneAbbreviation = format(Date.now(), "zzz", {
    timeZone: currentUserTimeZone,
    locale: enAU,
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.fetchHospitals();
      setData(result.data);
    };
    fetchData();
  }, []);

  const [data, setData] = useState<Hospital[]>([]);

  const fetcher = useFetcher();
  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
  } = useForm<Partial<CreateSecondaryMissionFormProps>>({
    resolver: zodResolver(createSecondaryMissionSchema),
    defaultValues: {
      isDropOffOnly: "false",
      referringHospital: 1,
      destinationHospital: 1,
      plannedDeparture: MissionPlannedDepartureEnum.Now,
      medicalTeam: MissionMedicalTeamEnum.FlightNurseOnly,
      specialCondition: MissionAssessmentConditionEnum.TotalMissionTime,
    },
    mode: "all",
  });

  const plannedDepartureValue = watch("plannedDeparture");
  const selectedReferringHospital = watch("referringHospital");
  const selectedDestinationHospital = watch("destinationHospital");
  const medicalTeamValue = watch("medicalTeam");

  const onSubmit = async (values: Partial<CreateSecondaryMissionFormProps>) => {
    const formData = new FormData();

    const transformedData: Partial<Mission> = {
      status: MissionStatusEnum.Draft,
      aviation_risk: values.riskScore,
      tasking_type: MissionTaskingTypeEnum.Secondary,
      planned_departure: values.plannedDeparture,
      medical_team: medicalTeamValue,
      planned_departure_at:
        values.departureTime && values.plannedDeparture !== "now"
          ? values.departureTime.toISOString()
          : plannedDepartureValue,
      assessment_condition: values.specialCondition,
      is_drop_off_only: values.isDropOffOnly === "true" ? true : false,
      patient_priority: values.priority,
      // @ts-ignore - needs to be updated in swagger gen types
      tasks_attributes: [
        {
          position: 100,
          task: "patient_transport",
          from_hospital_id: selectedReferringHospital,
          to_hospital_id: selectedDestinationHospital,
        },
      ],
    };

    formData.append("mission", JSON.stringify(transformedData));

    fetcher.submit(formData, {
      action: "/main/dashboard/mission/create/secondary",
      method: "post",
    });
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ gap: 2 }}>
      <Stack
        sx={{
          flexGrow: 1,
          p: 4,
          border: `2px solid ${COLORS.WHITE_SMOKE}`,
          width: "100%",
          borderRadius: "20px",
          borderTop: 0,
          borderStartStartRadius: 0,
          borderStartEndRadius: 0,
        }}
      >
        <Stack sx={{ width: "100%", maxWidth: 720, marginLeft: "10%", gap: 4, alignItems: "flex-start" }}>
          <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
            <Controller
              name="priority"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormSelect
                  label="Priority"
                  required
                  placeholder="Select a priority"
                  displayEmpty={true}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  sx={{ minWidth: 350 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <DoubleArrowUpIcon />
                    </InputAdornment>
                  }
                  items={PRIORITY_SELECT_ITEMS}
                />
              )}
            />
            <Controller
              name="riskScore"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormSelect
                  label="Risk score"
                  placeholder="Select a risk score"
                  displayEmpty={true}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                  sx={{ minWidth: 350 }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Dashboard3Icon />
                    </InputAdornment>
                  }
                  items={RISK_SCORE_SELECT_ITEMS}
                />
              )}
            />
          </Stack>
          <Controller
            name="specialCondition"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormSelect
                label="Special conditions"
                placeholder="Select a mission condition"
                displayEmpty={true}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                sx={{ minWidth: 350 }}
                startAdornment={
                  <InputAdornment position="start">
                    <AlignJustifyIcon />
                  </InputAdornment>
                }
                items={SPECIAL_CONDITION_SELECT_ITEMS}
              />
            )}
          />
          <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
            <Controller
              name="referringHospital"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <CustomInputLabel sx={{ marginBottom: "8px" }} required>
                    Referring Hospital
                  </CustomInputLabel>
                  <CustomSelect
                    placeholder="Select a referring hospital"
                    displayEmpty={true}
                    renderValue={(value) => {
                      const hospital = data.find((hospital) => hospital.id === value);
                      return hospital ? hospital.name : "Select a hospital";
                    }}
                    {...field}
                    size="small"
                    sx={{ minWidth: 350 }}
                    variant="outlined"
                    IconComponent={ChevronDownIcon}
                    MenuProps={{
                      disableAutoFocusItem: true,
                      MenuListProps: { sx: { bgcolor: COLORS.DR_WHITE } },
                    }}
                  >
                    {data.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              )}
            />
          </Stack>
          <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
            <Controller
              name="destinationHospital"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <CustomInputLabel sx={{ marginBottom: "8px" }} required>
                    Destination Hospital
                  </CustomInputLabel>
                  <CustomSelect
                    placeholder="Select a destination hospital"
                    displayEmpty={true}
                    renderValue={(value) => {
                      const hospital = data.find((hospital) => hospital.id === value);
                      return hospital ? hospital.name : "Select a hospital";
                    }}
                    {...field}
                    size="small"
                    sx={{ minWidth: 350 }}
                    variant="outlined"
                    IconComponent={ChevronDownIcon}
                    MenuProps={{
                      disableAutoFocusItem: true,
                      MenuListProps: { sx: { bgcolor: COLORS.DR_WHITE } },
                    }}
                  >
                    {data.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              )}
            />
          </Stack>
          <Stack sx={{ gap: 1 }}>
            <Typography variant="h2">Logistics</Typography>
            <Typography>Select the relevant mission times</Typography>
          </Stack>
          <Controller
            name="plannedDeparture"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Planned departure"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={PLANNED_DEPARTURE_OPTIONS}
              />
            )}
          />
          {plannedDepartureValue === MissionPlannedDepartureEnum.SpecificTime ? (
            <Controller
              name="departureTime"
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <FormDateTimePicker
                  icon="clock"
                  label={`Departure time (${currentUserTimeZoneAbbreviation})`}
                  required
                  placeholder="Enter the departing time"
                  sx={{ width: 350 }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={error?.message}
                />
              )}
            />
          ) : null}
          <Controller
            name="isDropOffOnly"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Drop off only"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={[
                  { label: "Yes", value: "true" },
                  { label: "No", value: "false" },
                ]}
              />
            )}
          />
          <Stack sx={{ gap: 1 }}>
            <Typography variant="h2">Medical team</Typography>
            <Typography>Select the medical team members</Typography>
          </Stack>
          <Controller
            name="medicalTeam"
            control={control}
            render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
              <FormRadioGroup
                fullWidth
                row
                label="Medical team"
                required
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={error?.message}
                items={MEDICAL_TEAM_OPTIONS}
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack direction="row" sx={{ alignItems: "flex-start", justifyContent: "flex-end", gap: 4 }}>
        {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}
        <LoadingButton
          type="submit"
          loading={fetcher.state === "loading" || fetcher.state === "submitting"}
          variant="contained"
          disabled={!isValid}
          sx={{ whiteSpace: "nowrap" }}
        >
          Next
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
