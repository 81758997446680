import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { COLORS } from "style";
import { ErrorAlert } from "component/ErrorAlert";
import { useMemo } from "react";
import { Link, Route, Routes, useFetcher, useMatch, useNavigate, useRouteLoaderData } from "react-router-dom";
import { format, utcToZonedTime } from "date-fns-tz";
import { DEFAULT_TIME_ZONE } from "constant";
import { AddCrewDialog, CrewMembers } from "module/common";
import { AssetView, CrewView } from "type/model/view";
import { NotSet } from "component/NotSet";
import { getTimeDifference } from "utility/dateTime";
import { enAU } from "date-fns/locale";
import { User } from "type/model/api";
import { pathWithSearchParams } from "utility/router";
import { AvailabilityCrewAssembly } from "./StatusSelect";

interface Props {
  asset: AssetView;
  allCrew: CrewView[];
}

export const AssetShifts = ({ asset, allCrew }: Props) => {
  const match = useMatch("/main/assets/:assetId/add-crew/:shiftId");

  const { currentUser } = useRouteLoaderData("main") as { currentUser: User };
  const navigate = useNavigate();
  const fetcher = useFetcher();

  const availableCrew = useMemo(() => {
    return allCrew.filter(
      ({ id }) =>
        !asset.crew.some(
          (crew) => crew.id === id && crew.shifts.find((crewShift) => crewShift.id.toString() === match?.params.shiftId)
        )
    );
  }, [allCrew, asset?.crew, match?.params.shiftId]);

  const handleDialogClose = () => {
    navigate(pathWithSearchParams("."));
  };

  const handleCrewUpdate: (
    shiftId: number | string,
    crew: { crewToAdd?: CrewView; crewToDelete?: CrewView }
  ) => void = (shiftId, { crewToAdd, crewToDelete }) => {
    if (!crewToAdd && !crewToDelete) {
      return;
    }

    const formData = new FormData();
    formData.append("action", "updateShiftCrew");
    formData.append("shiftId", shiftId.toString());
    const crew: { crewIdToAdd?: number; crewIdToDelete?: number } = {};

    if (crewToAdd) {
      crew.crewIdToAdd = crewToAdd.id;
    }
    if (crewToDelete) {
      crew.crewIdToDelete = crewToDelete.id;
    }

    formData.append("crew", JSON.stringify(crew));

    fetcher.submit(formData, {
      action: `/main/assets/${asset.id}`,
      method: "post",
    });
  };

  const handleAddCrew = (crewToAdd: CrewView | null) => {
    const shiftId = match?.params.shiftId;

    if (shiftId && crewToAdd) {
      handleCrewUpdate(shiftId, { crewToAdd });
    }

    handleDialogClose();
  };

  const handleDeleteCrew = (shiftId: number) => (crewToDelete: CrewView) => {
    handleCrewUpdate(shiftId, { crewToDelete });
  };

  const handleChangeCrew = (shiftId: number, crewToDelete: CrewView) => (crewToAdd: CrewView) => {
    handleCrewUpdate(shiftId, { crewToAdd, crewToDelete });
  };

  const renderStartTime = (start: Date | null) => {
    if (!start) {
      return <NotSet label="Invalid" />;
    }

    return `${format(utcToZonedTime(start, currentUser.time_zone || DEFAULT_TIME_ZONE), "HH:mm")} ${format(
      utcToZonedTime(Date.now(), currentUser.time_zone || DEFAULT_TIME_ZONE),
      "zzz",
      {
        locale: enAU,
      }
    )}`;
  };

  const renderDuration = (start: Date | null, end: Date | null) => {
    if (!start || !end) {
      return <NotSet label="Invalid" />;
    }
    return `${getTimeDifference(start, end)} HRS`;
  };

  return (
    <>
      {asset.shifts.length > 0 ? (
        <TableContainer
          sx={{
            border: `1px solid ${COLORS.DISCO_BALL}`,
            borderRadius: "4px",
            "& th": {
              borderColor: COLORS.DISCO_BALL,
              fontSize: (theme) => theme.typography.subtitle1,
              color: COLORS.DARK_ELF,
            },
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Shift</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Normal hours</TableCell>
                <TableCell>Surge hours</TableCell>
                <TableCell>Crew</TableCell>
                <TableCell>Availability</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {asset.shifts.map((shift) => (
                <TableRow
                  key={shift.id}
                  sx={{
                    verticalAlign: "top",
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td, & th": {
                      borderColor: COLORS.DISCO_BALL,
                      fontSize: (theme) => theme.typography.body2.fontSize,
                      color: COLORS.DARK_ELF,
                    },
                  }}
                >
                  <TableCell>
                    <Typography variant="h6" sx={{ color: shift.color ?? COLORS.TROLLEY_GREY }}>
                      {shift.name}
                    </Typography>
                  </TableCell>
                  <TableCell>{renderStartTime(shift.startTime)}</TableCell>
                  <TableCell>{renderDuration(shift.startTime, shift.endTime)}</TableCell>
                  <TableCell>{renderDuration(shift.startTime, shift.surgeEndTime)}</TableCell>
                  <TableCell>
                    <Stack sx={{ gap: 2, maxWidth: 400 }}>
                      {asset.crew
                        .filter((crew) => crew.shifts.find((crewShift) => crewShift.id === shift.id))
                        .map((crew) => (
                          <CrewMembers
                            key={crew.id}
                            showShift={false}
                            value={crew}
                            onChange={handleChangeCrew(shift.id, crew)}
                            allCrew={allCrew}
                            onRemove={handleDeleteCrew(shift.id)}
                            shiftId={shift.id}
                          />
                        ))}
                      <Button
                        variant="text"
                        component={Link}
                        to={pathWithSearchParams(`add-crew/${shift.id}`)}
                        disabled={!availableCrew.length}
                        sx={{ color: COLORS.HAILEY_BLUE, alignSelf: "flex-start", p: 0 }}
                      >
                        + Add crew member
                      </Button>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ width: 500 }}>
                    <Stack sx={{ gap: 2, width: 500 }}>
                      <AvailabilityCrewAssembly shiftId={shift.id} />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert severity="info">No shifts has been setup for this asset</Alert>
      )}

      {fetcher.data?.formError ? <ErrorAlert>{fetcher.data.formError}</ErrorAlert> : null}

      <Routes>
        <Route
          path="add-crew/:shiftId"
          element={<AddCrewDialog allCrew={availableCrew} onClose={handleDialogClose} onConfirm={handleAddCrew} />}
        />
      </Routes>
    </>
  );
};
