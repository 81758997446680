import { isAfter, isBefore, isFuture } from "date-fns";
import {
  MissionAssessmentConditionEnum,
  MissionAviationRiskEnum,
  MissionMedicalTeamEnum,
  MissionPatientPriorityEnum,
  MissionPlannedDepartureEnum,
} from "type/model/api";
import { LegLegTypeEnum, LegStageEnum, MissionWithRelatedAviationRiskEnum } from "type/model/api";
import { UnavailabilityOptions } from "type/model/view";
import * as z from "zod";

export const loginSchema = z.object({
  email: z.string().min(1, "Email address is required").email("Invalid email address").trim(),
  password: z.string().min(1, "Password is required"),
});
export type LoginFormProps = z.infer<typeof loginSchema>;

export const forgotPasswordSchema = z.object({
  email: z.string().min(1, "Email address is required").email("Invalid email address").trim(),
});
export type ForgotPasswordFormProps = z.infer<typeof forgotPasswordSchema>;

export const setPasswordSchema = z
  .object({
    password: z
      .string()
      .min(12, "Password must be at least 12 characters")
      .regex(/(?=.*[A-Z])/, "Password must include one upper case")
      .regex(/(?=.*[a-z])/, "Password must include one lower case")
      .regex(/(?=.*\d)/, "Password must include one number")
      .regex(/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/, "Password must include one punctuation mark"),

    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export type SetPasswordFormProps = z.infer<typeof setPasswordSchema>;

export const createOtherMissionSchema = z.object({
  asset: z.coerce.number({
    required_error: "Asset selection is required",
    invalid_type_error: "Asset selection is required",
  }),
  comments: z.string().optional(),
  medicalResponse: z
    .enum(["false", "true"], { required_error: "Medical mission response is required" })
    .transform((value) => value === "true"),
  departureTime: z.date({ required_error: "Departure time is required" }),
  baseEta: z.date({ required_error: "Estimate time for return to base is required" }),
  recallTime: z.preprocess(
    (args) => (args === "" ? undefined : args),
    z.coerce
      .number({ invalid_type_error: "Recall time must be a number" })
      .int("Recall time must be integer")
      .positive("Recall time must be positive")
      .optional()
  ),
});
export type CreateOtherMissionFormProps = z.infer<typeof createOtherMissionSchema>;

export const createMissionLegSchema = z.object({
  fromText: z.string().min(1, "From is required"),
  toText: z.string().min(1, "To is required"),
  legType: z.nativeEnum(LegLegTypeEnum, { required_error: "Type is required" }),
  stage: z.nativeEnum(LegStageEnum, { required_error: "Stage is required" }),
});
export type CreateMissionLegFormProps = z.infer<typeof createMissionLegSchema>;

export const editMissionLegsSchema = z
  .object({
    missionLegs: z.array(
      z.object({
        fromText: z.string().min(1, "From is required"),
        toText: z.string().min(1, "To is required"),
        startTime: z.date({ invalid_type_error: "Invalid" }).nullable(),
        endTime: z.date({ invalid_type_error: "Invalid" }).nullable(),
        sarTime: z.date({ invalid_type_error: "Invalid" }).nullable(),
        pob: z.preprocess(
          (args) => (args === "" ? undefined : args),
          z.coerce
            .number({ invalid_type_error: "POB must be a number" })
            .int("POB time must be integer")
            .positive("POB time must be positive")
            .nullable()
        ),
      })
    ),
  })
  .superRefine(({ missionLegs }, ctx) => {
    missionLegs.forEach(({ startTime, endTime, sarTime }, index) => {
      if (endTime && !startTime) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `ETD must be entered before ETA`,
          path: ["missionLegs", index, "endTime"],
          fatal: true,
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `ETD must be entered before ETA`,
          path: ["missionLegs", index, "startTime"],
          fatal: true,
        });
        return z.NEVER;
      }

      if (endTime && startTime && !isAfter(endTime, startTime)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `ETA must be after ETD`,
          path: ["missionLegs", index, "endTime"],
          fatal: true,
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `ETD must be before ETA`,
          path: ["missionLegs", index, "startTime"],
          fatal: true,
        });
        return z.NEVER;
      }
      if (sarTime && startTime && !isAfter(sarTime, startTime)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `SAR must be after ETD`,
          path: ["missionLegs", index, "sarTime"],
          fatal: true,
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `ETD must be before SAR`,
          path: ["missionLegs", index, "startTime"],
          fatal: true,
        });
        return z.NEVER;
      }

      return true;
    });
  });

export const createSecondaryMissionSchema = z
  .object({
    priority: z.nativeEnum(MissionPatientPriorityEnum, { required_error: "Priority is required" }),
    riskScore: z.nativeEnum(MissionAviationRiskEnum).optional(),
    specialCondition: z.nativeEnum(MissionAssessmentConditionEnum).optional(),
    referringHospital: z
      .number({ required_error: "Referring hospital is required" })
      .min(1, "Referring hospital is required"),
    destinationHospital: z
      .number({ required_error: "Destination hospital is required" })
      .min(1, "Destination hospital is required"),

    plannedDeparture: z.nativeEnum(MissionPlannedDepartureEnum, {
      required_error: "Planned departure is required",
    }),
    medicalTeam: z.nativeEnum(MissionMedicalTeamEnum, { required_error: "Medical team is required" }),
    departureTime: z.date().nullable().optional(),
    isDropOffOnly: z.enum(["false", "true"], { required_error: "Drop off only is required" }),
  })
  .superRefine(({ plannedDeparture, departureTime }, ctx) => {
    if (plannedDeparture === MissionPlannedDepartureEnum.SpecificTime && !departureTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Departure time is required",
        path: ["departureTime"],
        fatal: true,
      });
      return z.NEVER;
    }
  });
export type CreateSecondaryMissionFormProps = z.infer<typeof createSecondaryMissionSchema>;

const latitudeValidator = z.coerce
  .number({
    required_error: "Latitude is required",
    invalid_type_error: "Latitude must be a number",
  })
  .refine((value) => value >= -90 && value <= 90, {
    message: "Latitude must be between -90 and 90",
  });

const longitudeValidator = z.coerce
  .number({
    required_error: "Longitude is required",
    invalid_type_error: "Longitude must be a number",
  })
  .refine((value) => value >= -180 && value <= 180, {
    message: "Longitude must be between -180 and 180",
  });

export const createPrimaryMissionSchema = z
  .object({
    priority: z.nativeEnum(MissionPatientPriorityEnum, { required_error: "Priority is required" }),
    riskScore: z.nativeEnum(MissionAviationRiskEnum).optional(),
    specialCondition: z.nativeEnum(MissionAssessmentConditionEnum).optional(),
    destinationHospital: z.number().min(1, "Destination hospital is required"),

    plannedDeparture: z.nativeEnum(MissionPlannedDepartureEnum, {
      required_error: "Planned departure is required",
    }),
    departureTime: z.date().nullable().optional(),
    medicalTeam: z.nativeEnum(MissionMedicalTeamEnum, { required_error: "Medical team is required" }),
    isWinchRequired: z.enum(["false", "true"], { required_error: "Winch option is required" }),
    latPickup: latitudeValidator,
    longPickup: longitudeValidator,
  })
  .superRefine(({ plannedDeparture, departureTime }, ctx) => {
    if (plannedDeparture === MissionPlannedDepartureEnum.SpecificTime && !departureTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Departure time is required",
        path: ["departureTime"],
        fatal: true,
      });
      return z.NEVER;
    }
  });
export type CreatePrimaryMissionFormProps = z.infer<typeof createPrimaryMissionSchema>;

export const createNetsMissionSchema = z
  .object({
    priority: z.nativeEnum(MissionPatientPriorityEnum, { required_error: "Priority is required" }),
    riskScore: z.nativeEnum(MissionAviationRiskEnum).optional(),
    specialCondition: z.nativeEnum(MissionAssessmentConditionEnum).optional(),
    referringHospital: z
      .number({ required_error: "Referring hospital is required" })
      .min(1, "Referring hospital is required"),
    destinationHospital: z
      .number({ required_error: "Destination hospital is required" })
      .min(1, "Destination hospital is required"),

    plannedDeparture: z.nativeEnum(MissionPlannedDepartureEnum, {
      required_error: "Planned departure is required",
    }),

    departureTime: z.date().nullable().optional(),
    isDropOffOnly: z.enum(["false", "true"], { required_error: "Drop off only is required" }),
  })
  .superRefine(({ plannedDeparture, departureTime }, ctx) => {
    if (plannedDeparture === MissionPlannedDepartureEnum.SpecificTime && !departureTime) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Departure time is required",
        path: ["departureTime"],
        fatal: true,
      });
      return z.NEVER;
    }
  });
export type CreateNetsMissionFormProps = z.infer<typeof createNetsMissionSchema>;

export const assetUnavailabilitySchema = z
  .object({
    option: z.nativeEnum(UnavailabilityOptions),
    from: z.date().nullable().optional(),
    to: z.date().nullable().optional(),
    comments: z.string().optional().nullable(),
  })
  .superRefine(({ from, to }, ctx) => {
    if (from && to && isBefore(to, from)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "End date must be after start date",
        path: ["to"],
        fatal: true,
      });
      return z.NEVER;
    }

    if (to && !isFuture(to)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "End date must be in future",
        path: ["to"],
        fatal: true,
      });
      return z.NEVER;
    }
  });

export type AssetUnavailabilitySchema = z.infer<typeof assetUnavailabilitySchema>;

export const editOtherMissionSchema = z.object({
  comments: z.string().nullable(),
  mru: z.string().nullable(),
  riskScore: z.nativeEnum(MissionWithRelatedAviationRiskEnum).nullable(),
  isMedicalMissionRespondAvailable: z.enum(["false", "true"], {
    required_error: "Medical mission response is required",
  }),
  plannedDepartureTime: z.date({
    required_error: "Departure time is required",
    invalid_type_error: "Departure time is required",
  }),
  estimatedReturnToBaseTime: z.date({
    required_error: "Estimate time for return to base is required",
    invalid_type_error: "Estimate time for return to base is required",
  }),
  recallTime: z.preprocess(
    (args) => (args === "" ? undefined : args),
    z.coerce
      .number({ invalid_type_error: "Recall time must be a number" })
      .int("Recall time must be integer")
      .positive("Recall time must be positive")
      .nullable()
      .optional()
  ),
  crew: z.array(z.any()),
});
export type EditOtherMissionFormProps = z.infer<typeof editOtherMissionSchema>;

export const editSecMissionSchema = z.object({
  // priority: z.nativeEnum(MissionWithRelatedPatientPriorityEnum, { required_error: "Priority is required" }),
  comments: z.string().nullable(),
  mru: z.string().nullable(),
  riskScore: z.nativeEnum(MissionWithRelatedAviationRiskEnum).nullable(),
  crew: z.array(z.any()),
});
export type EditSecMissionFormProps = z.infer<typeof editSecMissionSchema>;
