import { Table, TableBody, TableRow, TableCell, Typography, Button, SxProps, Theme, Stack } from "@mui/material";
import { MissionWithRelatedStatusEnum } from "type/model/api";
import { Crew } from "./Crew";
import { MissionStage } from "./MissionStage";
import { COLORS } from "style";
import { AssetView, MissionView } from "type/model/view";
import { Link } from "react-router-dom";
import { pathWithSearchParams } from "utility/router";
import { isBetweenTime } from "utility/dateTime";

interface Props {
  asset?: AssetView;
  assetMissions: MissionView[];
}

const tableRowStyle: SxProps<Theme> = {
  verticalAlign: "top",
};

const crewTableCellStyle = { width: 250, px: 2, py: 2, pb: 4, border: 0 };
const stageTableCellStyle = { p: 2, border: 0 };
const viewTableCellStyle = { textAlign: "right", border: 0, p: 2 };

export const AssetMissions = ({ asset, assetMissions }: Props) => {
  const hasMissions = assetMissions.length > 0;

  const renderCurrentCrew = (asset: AssetView) => {
    const now = Date.now();
    const currentShift = asset.shifts.find(
      (shift) => shift.startTime && shift.endTime && isBetweenTime(now, shift.startTime, shift.endTime)
    );
    if (!currentShift) {
      return <Typography variant="body2">No current shift</Typography>;
    }
    const currentShiftCrew = asset.crew.filter((crew) => crew.shifts.find((shift) => shift.id === currentShift.id));
    return <Crew crew={currentShiftCrew} />;
  };

  const renderTableRows = () => {
    if (!hasMissions) {
      return (
        <TableRow sx={tableRowStyle}>
          <TableCell sx={crewTableCellStyle}>{asset ? renderCurrentCrew(asset) : "Null"}</TableCell>
          <TableCell colSpan={2} sx={stageTableCellStyle}>
            <Typography variant="body2">No current mission</Typography>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <>
        {assetMissions?.map((mission) => {
          const bgcolor =
            mission.status === MissionWithRelatedStatusEnum.Cancelled ||
            mission.status === MissionWithRelatedStatusEnum.Complete
              ? COLORS.DOCTOR
              : COLORS.WHITE;
          return (
            <TableRow key={mission.id} sx={{ ...tableRowStyle, bgcolor }}>
              <TableCell sx={crewTableCellStyle}>
                <Crew crew={mission.crew} />
              </TableCell>
              <TableCell sx={stageTableCellStyle}>
                <Stack sx={{ alignItems: "flex-start" }}>
                  <MissionStage mission={mission} />
                </Stack>
              </TableCell>
              <TableCell sx={viewTableCellStyle}>
                <Button
                  component={Link}
                  to={
                    mission.assetId
                      ? pathWithSearchParams(`mission/edit/${mission.id}/details`)
                      : pathWithSearchParams(`/main/dashboard/mission/${mission.id}/recommendations`)
                  }
                  variant="outlined"
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <Table>
      <TableBody>{renderTableRows()}</TableBody>
    </Table>
  );
};
